import React from 'react';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import Timer from '../images/timer-text-color.svg';
import { Image } from '../components/Image';
import { border, borderRadius } from '../constants/constant';
import { colors } from '../constants/colors';

export const ProjectCard = ({
    title,
    path,
    introduction,
    time,
    headImage,
    projectMode,
    projectSpan,
}) => {
    const tagModeStyle = css`
        color: ${colors.background};
        background-color: ${projectMode === 'hybrid'
            ? colors.label2
            : colors.label1};
    `;
    return (
        <Link to={path} css={[cardStyle, borderStyle]}>
            <div css={headImageStyle}>
                <Image
                    imageName={headImage}
                    alt="project_head_image"
                    imgStyle={{
                        height: '144px',
                    }}
                />
            </div>
            <div css={tagBoxStyle}>
                <div css={[tagStyle, tagModeStyle]}>
                    {projectMode === 'hybrid' ? 'ハイブリッド' : 'オンライン'}
                </div>
                <div css={[tagStyle, tagSpanStyle]}>
                    {projectSpan === 'temporary' ? '五月祭期間限定' : '常設'}
                </div>
            </div>
            <div css={titleStyle}>{title}</div>
            <div css={timerBoxStyle}>
                <img src={Timer} alt="Timer" css={timerIconStyle} />
                <p css={timerTextStyle}>{time}</p>
            </div>
            <div css={introStyle}>{introduction}</div>
        </Link>
    );
};

const borderStyle = css`
    border: ${border} solid ${colors.primary};
`;
const cardStyle = css`
    display: block;
    text-align: center;
    width: 253px;
    height: 336px;
    border-radius: ${borderRadius};
    color: ${colors.white};
    text-decoration: none;
    background-color: ${colors.clickable};
    transition: 0.3s ease-in-out;
    &:hover {
        transform: scale(1.03);
    }
`;
const headImageStyle = css`
    width: 247px;
    height: 144px;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
`;
const tagStyle = css`
    border-radius: 4px;
    margin-top: 4px;
    padding: 2px 5px;
`;
const tagSpanStyle = css`
    color: ${colors.white};
    background-color: ${colors.boundaryWhite};
`;
const tagBoxStyle = css`
    display: flex;
    position: relative;
    left: 4px;
    font-family: 'M PLUS 1';
    font-size: 10px;
    line-height: 14px;
    text-align: left;
    gap: 4px;
`;
const titleStyle = css`
    font-size: 24px;
    font-weight: 400;
    height: 35px;
    line-height: 34.75px;
    margin-top: 4px;
`;
const timerBoxStyle = css`
    display: flex;
    justify-content: center;
    margin-top: 4px;
`;
const timerTextStyle = css`
    line-height: 23px;
    font-size: 16px;
    margin: 0;
`;
const timerIconStyle = css`
    margin: 0 3px;
`;
const introStyle = css`
    font-size: 16px;
    margin: 4px 8px 0 8px;
    line-height: 23px;
`;
