import { css } from '@emotion/react';
import React from 'react';
import { LinkButtonExternal } from '../LinkButton';

export const MayFes = () => {
    return (
        <div css={style}>
            <div css={sentenceStyle}>
                <p>
                    第95回五月祭では、キャンパスに入構いただくには入構チケットが必要です。入構チケットの申し込み期間は終了しました。
                </p>
            </div>
            <div css={buttonAlignmentStyle}>
                <LinkButtonExternal
                    title="第95回五月祭サイト"
                    url="https://gogatsusai.jp/95/visitor/entry"
                />
            </div>
        </div>
    );
};

const style = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const buttonAlignmentStyle = css`
    display: inline-block;
`;

const sentenceStyle = css`
    display: inline-block;
    text-align: left;
`;
