import * as React from 'react';
import { SectionEyecatch } from '../components/SectionEyecatch';
import { css } from '@emotion/react';
import { ContentSection } from '../components/ContentSection';
import Layout from '../components/layout';
import { Seo } from '../components/Seo';
import { breakPoints } from '../constants/constant';
import { Image } from '../components/Image';

const bldg2FourthFloorMap = 'access/bldg2FourthFloorMap.png';
const campusMap = 'access/campus-map-detailed.png';

const AccessPage = () => {
    return (
        <Layout>
            <Seo title="アクセス" pathname="/access" />
            <SectionEyecatch pageTitle="アクセス" />
            <ContentSection title="工学部2号館4階マップ">
                <div css={staticMapStyle}>
                    <Image
                        imageName={bldg2FourthFloorMap}
                        alt="工学部2号館4階マップ"
                    />
                </div>
            </ContentSection>
            <ContentSection title="本郷キャンパス構内マップ">
                <div css={staticMapStyle}>
                    <Image
                        imageName={campusMap}
                        alt="https://gogatsusai.jp/95/visitor/_nuxt/img/campus-map-detailed.b6bb48b.png"
                    />
                </div>
            </ContentSection>
            <ContentSection title="アクセス">
                <div
                    css={{
                        textAlign: 'center',
                        display: 'block',
                    }}
                >
                    <ul
                        css={{
                            paddingLeft: 0,
                            marginBottom: 0,
                            display: 'inline-block',
                        }}
                    >
                        <li css={liStyle}>
                            東京メトロ丸ノ内線「本郷三丁目」駅
                            2番出口より徒歩15分
                        </li>
                        <li css={liStyle}>
                            都営大江戸線「本郷三丁目」駅 4番出口より徒歩10分
                        </li>
                        <li css={liStyle}>
                            東京メトロ南北線「東大前」駅 1番出口より徒歩8分
                        </li>
                        <li css={liStyle}>
                            東京メトロ千代田線「根津」駅 1番出口より徒歩8分
                        </li>
                    </ul>
                    <div css={wrapperStyle}>
                        <div css={mapStyle}>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.477812431934!2d139.75961191487426!3d35.71446573575669!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c31cd6b36fb%3A0xcb7e467e917c3672!2z5p2x5Lqs5aSn5a2m5bel5a2m6YOoMuWPt-mkqA!5e0!3m2!1sja!2sjp!4v1650363462731!5m2!1sja!2sjp"
                                title="Google Map"
                                height="100%"
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                css={{
                                    height: 100 + '%',
                                    width: 100 + '%',
                                }}
                            ></iframe>
                        </div>
                    </div>
                </div>
            </ContentSection>
        </Layout>
    );
};

const liStyle = css`
    text-align: left;
    margin-bottom: 0;
`;

const wrapperStyle = css`
    margin: 0 auto;
    position: relative;
    width: 100%;
    margin-top: 32px;
    @media (max-width: ${breakPoints.mobile}) {
        padding-top: 75%;
    } ;
`;

const staticMapStyle = css`
    text-align: center;
`;

const mapStyle = css`
    @media (min-width: ${breakPoints.mobile}) {
        margin: 0 auto;
        position: relative;
        width: 640px;
        height: 480px;
    }
    @media (max-width: ${breakPoints.mobile}) {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
    } ;
`;

export default AccessPage;
