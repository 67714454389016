export const breakPoints = {
    pc: '1140px',
    tablet: '1080px',
    mobile: '640px',
};

export const border = '3px';

export const borderRadius = '8px';

export const dropShadow = '0px 3px 10px #000000';

export const timelineSize = {
    height: 480,
    width: 560,
};
