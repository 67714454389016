import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { colors } from '../../constants/colors';

const MenuContentText = ({ title, isProject, children }) => {
    return (
        <>
            <FontAwesomeIcon
                icon={faAnglesRight}
                css={
                    isProject
                        ? [menuContentAngleStyle, menuContentAngleYellowStyle]
                        : menuContentAngleStyle
                }
            />
            <div css={menuContentTextStyle}>{title}</div>
            <div>{children}</div>
        </>
    );
};

export const MenuContent = ({
    title,
    link,
    children,
    isExternal,
    isProject,
}) => {
    return (
        <>
            {isExternal ? (
                <a
                    css={menuContentAStyle}
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                >
                    <MenuContentText {...{ title, isProject }}>
                        {children}
                    </MenuContentText>
                </a>
            ) : (
                <Link css={menuContentLinkStyle} to={link}>
                    <MenuContentText {...{ title, isProject }}>
                        {children}
                    </MenuContentText>
                </Link>
            )}
        </>
    );
};

const menuContentAngleStyle = css`
    margin-left: 8px;
`;

const menuContentAngleYellowStyle = css`
    color: ${colors.primary};
`;

const menuContentTextStyle = css`
    margin-left: 8px;
    margin-right: 12px;
`;

const menuContentAStyle = css`
    text-decoration: none;
    cursor: pointer;
    color: ${colors.white};
    display: flex;
    align-items: center;
    padding: 8px 0 8px 28px;
    &:hover {
        background-color: ${colors.clickable};
    }
`;

const menuContentLinkStyle = css`
    text-decoration: none;
    color: ${colors.white};
    display: flex;
    align-items: center;
    padding: 8px 0 8px 28px;
    &:hover {
        background-color: ${colors.clickable};
    }
`;

MenuContentText.defaultProps = {
    title: '',
    isProject: false,
    children: <></>,
};

MenuContentText.propTypes = {
    title: PropTypes.string.isRequired,
    isProject: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};

MenuContent.defaultProps = {
    title: '',
    link: '/',
    children: <></>,
    isExternal: false,
    isProject: false,
};

MenuContent.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    isExternal: PropTypes.bool.isRequired,
    isProject: PropTypes.bool.isRequired,
};
