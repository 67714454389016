import * as React from 'react';
import { css } from '@emotion/react';
import { breakPoints } from '../../constants/constant';
import { colors } from '../../constants/colors';

export const Copyright = () => {
    return (
        <div css={copyrightStyle}>
            &copy; {new Date().getFullYear()}
            {` `}
            東京大学工学部
            <br css={breakStyle} /> 電子情報工学科・電気電子工学科
            <br css={breakStyle} /> 学生展示
        </div>
    );
};

const copyrightStyle = css`
    text-align: center;
    border-top: 2px solid ${colors.boundaryWhite};
    padding-top: 1rem;
`;

const breakStyle = css`
    display: none;
    @media (max-width: ${breakPoints.mobile}) {
        display: block;
    } ;
`;
