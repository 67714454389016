import * as React from 'react';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { colors } from '../../constants/colors';
import { border } from '../../constants/constant';

export const HeaderContent = ({ title, link, currentPath }) => {
    return (
        <Link
            css={
                currentPath === link
                    ? headerContentCurrentLinkStyle
                    : headerContentLinkStyle
            }
            to={link}
        >
            {title}
        </Link>
    );
};

const headerContentLinkStyleBase = css`
    margin-left: 16px;
    text-decoration: none;
    color: ${colors.white};
    align-items: center;
    position: relative;
    &::after {
        position: absolute;
        left: 0;
        bottom: -4px;
        content: '';
        background-color: ${colors.primary};
        width: 100%;
        height: ${border};
    }
`;

const headerContentLinkStyle = css`
    ${headerContentLinkStyleBase}
    &::after {
        transform: scale(0, 1);
        transform-origin: center top;
        transition: transform 0.3s;
    }
    &:hover::after {
        transform: scale(1, 1);
    }
`;

const headerContentCurrentLinkStyle = css`
    ${headerContentLinkStyleBase}
`;

HeaderContent.defaultProps = {
    title: '',
    link: '/',
};

HeaderContent.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
};
