import * as React from 'react';
import { SectionEyecatch } from '../components/SectionEyecatch';
import { ExhibitionGroup } from '../components/exhibition/ExhibitionGroup';
import { ExhibitionDetailGroup } from '../components/exhibition/ExhibitionDetailGroup';
import { RecommendSection } from '../components/RecommendSection';

import Layout from '../components/layout';
import { Seo } from '../components/Seo';

const ExhibitionPage = () => (
    <Layout>
        <Seo
            title="学生制作物展示"
            pathname="/exhibition"
            description="2021年度3年Aセメスターの授業で生まれた作品たちの一部をご紹介します。"
        />
        <SectionEyecatch
            pageTitle="学生制作物展示"
            pageSentences="2021年度3年Aセメスターの授業で生まれた作品たちの一部をご紹介します。"
            imageName="hero-image.png"
        />
        <ExhibitionGroup />
        <ExhibitionDetailGroup />
        <RecommendSection recommendData={recommendData} />
    </Layout>
);

const recommendData = [
    {
        title: '電子工作教室',
        introduction: '簡単な電子工作を体験してみよう！',
        path: '/electronic-workshop',
    },
    {
        title: '謎解き',
        introduction: '君の力で、謎解きAIロボットを完成させよう！',
        path: '/mystery',
    },
];

export default ExhibitionPage;
