import { css } from '@emotion/react';
import React from 'react';
import { border, borderRadius } from '../constants/constant';
import { colors } from '../constants/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';

export const LinkButtonExternal = ({ title, url }) => {
    return (
        <div css={style}>
            <a
                href={url}
                target="_blank"
                className="exit"
                rel="noreferrer"
                css={buttonStyle}
            >
                {title}
                <FontAwesomeIcon icon={faUpRightFromSquare} css={iconStyle} />
            </a>
        </div>
    );
};

export const LinkButtonInternal = ({ title, path }) => {
    return (
        <div css={style}>
            <Link to={path} css={buttonStyle}>
                {title}
            </Link>
        </div>
    );
};

const style = css`
    text-align: center;
    transition-duration: 0.2s;
    width: fit-content;
    &:hover {
        transform: scale(1.1);
    }
`;

const buttonStyle = css`
    border-radius: ${borderRadius};
    border: ${border} solid ${colors.primary};
    padding: 8px;
    color: ${colors.white};
    text-decoration: none;
    background-color: ${colors.clickable};
`;

const iconStyle = css`
    margin-left: 8px;
`;
