import * as React from 'react';
import { css } from '@emotion/react';
import { Image } from '../Image';
import { colors } from '../../constants/colors';

const imgStyle = {
    width: '200px',
    height: '200px',
};

export const SelfIntroduction = () => {
    return (
        <div css={introListStyle}>
            <div css={[cardStyle, leftStyle]}>
                <div css={contentStyle}>
                    <p css={emptyStyle}></p>
                    電気電子工学科でよくみんなに頼りになるって言われます。
                    <br />
                    一押しの企画は電子工作教室です。
                </div>
                <div css={imgflexStyle}>
                    <p css={nameStyle}>みつき</p>
                    <Image
                        imageName="mitsuki.png"
                        imgStyle={imgStyle}
                        alt="mitsuki"
                    />
                </div>
            </div>
            <div css={[cardStyle, rightStyle]}>
                <div css={imgflexStyle}>
                    <p css={nameStyle}>えれな</p>
                    <Image
                        imageName="erena.png"
                        imgStyle={imgStyle}
                        alt="erena"
                    />
                </div>
                <div css={contentStyle}>
                    <p css={emptyStyle}></p>
                    電子情報工学科でおしゃべりが好きです。
                    <br />
                    お気に入りの企画はプログラミング教室です。
                </div>
            </div>
        </div>
    );
};

const introListStyle = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px 50px;

    text-align: center;
`;

const cardStyle = css`
    display: flex;
    justify-content: center;
    gap: 0 10px;
`;

const leftStyle = css`
    flex-wrap: wrap-reverse;
`;
const rightStyle = css`
    flex-wrap: wrap;
`;

const contentStyle = css`
    width: 350px;
`;

const imgflexStyle = css`
    width: 200px;
`;

const emptyStyle = css`
    height: 40px;
    @media screen and (max-width: 592px) {
        height: 0px;
    } ;
`;

const nameStyle = css`
    width: fit-content;
    font-size: 24px;
    margin: 0px auto 10px;
    padding: 0 4px;
    height: 40px;

    border-bottom: medium solid ${colors.primary};
`;
