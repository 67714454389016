import { css, keyframes } from '@emotion/react';
import { colors } from '../../constants/colors';
import * as React from 'react';

export const SidebarButton = ({ isOpen, setIsOpen }) => {
    return (
        <>
            <div
                css={isOpen ? closeIconStyle : menuIconStyle}
                onClick={() => setIsOpen(!isOpen)}
                onKeyDown={() => setIsOpen(!isOpen)}
                role="button"
                tabIndex="0"
            >
                <div css={menuIconFrameStyle}>
                    <div css={isOpen ? closeSpanStyle : openSpanStyle}></div>
                    <div css={isOpen ? closeSpanStyle : openSpanStyle}></div>
                    <div css={isOpen ? closeSpanStyle : openSpanStyle}></div>
                </div>
                <div css={menuIconTextStyle}>{isOpen ? 'CLOSE' : 'MENU'}</div>
            </div>
        </>
    );
};

const closeIconStyle = css`
    cursor: pointer;
    position: relative;
    z-index: 100;
    width: 50px;
    height: 50px;
    border: 2px solid ${colors.white};
    border-radius: 10%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
`;

const closeAnim1 = keyframes`
    0% {
        top: 1px;
        left: 4px;
        transform: rotate(0);
    }

    50% {
        transform: rotate(0);
        top: 8px;
        left: 5px;
    }

    100% {
        transform: rotate(40deg);
        top: 10px;
        left: 5px;
    }
}
`;

const closeAnim2 = keyframes`
    0% {
        top: 15px;
        left: 4px;
        transform: rotate(0);
    }

    50% {
        transform: rotate(0);
        top: 8px;
        left: 5px;
    }

    100% {
        transform: rotate(-40deg);
        top: 10px;
        left: 5px;
    }
`;

const closeSpanStyle = css`
    position: absolute;
    top: 10px;
    left: 5px;
    height: 2px;
    width: 35px;
    margin-top: 5px;
    background-color: ${colors.white};

    &:first-of-type {
        transform: rotate(40deg);
        animation: ${closeAnim1} 0.3s linear 0s;
    }
    &:nth-of-type(2) {
        visibility: hidden;
    }
    &:last-child {
        transform: rotate(-40deg);
        animation: ${closeAnim2} 0.3s linear 0s;
    }
`;

const openAnim1 = keyframes`
    0% {
        transform: rotate(45deg);
        top: 10px;
        left: 6px;
    }

    70% {
        transform: rotate(0);
        top: 10px;
        left: 6px;
    }

    100% {
        top: 18px;
        left: 6px;
        transform: rotate(0);
    }
`;

const openAnim2 = keyframes`
    0% {
        transform: rotate(45deg);
        top: 10px;
        left: 6px;
    }

    50% {
        transform: rotate(0);
        top: 10px;
        left: 6px;
    }

    70% {
        transform: rotate(0);
        top: 10px;
        left: 6px;
    }

    100% {
        top: 2px;
        left: 6px;
        transform: rotate(0);
    }
`;

const openSpanStyle = css`
    position: absolute;
    height: 2px;
    width: 35px;
    margin-top: 5px;
    background-color: ${colors.white};
    &:first-of-type {
        top: 2px;
        left: 6px;
        animation: ${openAnim1} 0.3s linear 0s;
    }

    &:nth-of-type(2) {
        top: 10px;
        left: 6px;
    }

    &:last-child {
        top: 18px;
        left: 6px;
        animation: ${openAnim2} 0.3s linear 0s;
    }
`;

const menuIconStyle = css`
    cursor: pointer;
    position: relative;
    z-index: 100;
    width: 50px;
    height: 50px;
    /* margin: 12px 12px; */
    border: 2px solid ${colors.white};
    border-radius: 10%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
`;

const menuIconFrameStyle = css`
    margin-top: 4px;
    height: 48px;
    width: 48px;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`;

const menuIconTextStyle = css`
    position: absolute;
    bottom: 0px;
    color: ${colors.white};
    font-size: 10px;
`;
