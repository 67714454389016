import * as React from 'react';
import { css } from '@emotion/react';
import { colors } from '../constants/colors';
import { NavgationBlock } from './footer/NavigationBlock';
import { Copyright } from './footer/Copyright';

export const Footer = () => {
    return (
        <footer css={footerStyle}>
            <NavgationBlock />
            <Copyright />
        </footer>
    );
};
const footerStyle = css`
    background-color: ${colors.footerBackground};
    padding: 16px;
`;
