import * as React from 'react';
import { css } from '@emotion/react';
import { Image } from '../Image';
import { colors } from '../../constants/colors';
import { border, borderRadius } from '../../constants/constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

const LinkBlock = ({ link, imageName, title, child }) => {
    return (
        <div css={style}>
            <a css={SnsAStyle} href={link} target="_brank" rel="noreferrer">
                <div css={imgStyle}>
                    <Image
                        imageName={imageName}
                        imgStyle={{
                            borderRadius: '50%',
                            width: '100%',
                            height: '100%',
                        }}
                        alt={title}
                    />
                </div>
                {title}
                <FontAwesomeIcon icon={child} css={iconStyle} />
            </a>
        </div>
    );
};

export const WebcastingSNS = () => {
    return (
        <div css={SNSStyle}>
            <LinkBlock
                link="https://www.youtube.com/channel/UCWPrxA-tgo7ePClMeZhMn0g"
                imageName="icon-sns.png"
                title="YouTube"
                child={faYoutube}
            />
            <LinkBlock
                link="https://twitter.com/mitsuele_eeic"
                imageName="icon-sns.png"
                title="Twitter"
                child={faTwitter}
            />
        </div>
    );
};

const style = css`
    flex-basis: 300px;
    text-align: center;
    transition-duration: 0.2s;
    &:hover {
        transform: scale(1.1);
    }
`;

const SnsAStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 24px;

    border-radius: ${borderRadius};
    border: ${border} solid ${colors.primary};
    padding: 8px;
    color: ${colors.white};
    text-decoration: none;
    background-color: ${colors.clickable};
`;

const imgStyle = css`
    width: 58px;
    height: 50px;
    padding-right: 8px;
`;

const iconStyle = css`
    margin-left: 8px;
`;

const SNSStyle = css`
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
`;
