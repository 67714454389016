import * as React from 'react';
import { css } from '@emotion/react';
import { colors } from '../../constants/colors';

const scheduleContents = [
    {
        id: 1,
        date: '5/14',
        contents: [
            {
                id: 11,
                start: '12:00',
                end: '13:00',
                name: '落合先生',
            },
            {
                id: 12,
                start: '13:00',
                end: '14:00',
                name: '藤本・清水・藤田研',
            },
            {
                id: 13,
                start: '14:00',
                end: '15:00',
                name: 'アイン先生',
            },
        ],
        url: 'https://www.youtube.com/embed/P4MMSVrySsg',
    },
    {
        id: 2,
        date: '5/15',
        contents: [
            {
                id: 21,
                start: '12:00',
                end: '13:00',
                name: '相澤先生',
            },
            {
                id: 22,
                start: '13:00',
                end: '14:00',
                name: '森川・成末研',
            },
            {
                id: 23,
                start: '14:00',
                end: '15:00',
                name: '松井先生',
            },
        ],
        url: 'https://www.youtube.com/embed/QmJOAtcBfpQ',
    },
];

const DaySchedule = ({ date, contents, id, url }) => {
    return (
        <div css={scheduleStyle} key={id}>
            <p css={dateStyle}>{date}</p>
            <div
                css={{
                    textAlign: 'center',
                    display: 'block',
                }}
            >
                <ul css={listStyle}>
                    {contents.map((content) => (
                        <li css={itemStyle} key={content.id}>
                            <div>
                                {content.start}〜{content.end}： {content.name}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <iframe
                css={movieStyle}
                src={url}
                title="YouTube video player"
                frameBorder="0"
                scrolling="no"
                allow="accelerometer; clipboard-write; encrypted-media; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export const TimeSchedule = () => {
    return (
        <div css={style}>
            {scheduleContents.map((props) => {
                return (
                    <DaySchedule
                        date={props.date}
                        contents={props.contents}
                        id={props.id}
                        url={props.url}
                        key={props.id}
                    />
                );
            })}
        </div>
    );
};

const style = css`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;
`;

const dateStyle = css`
    width: fit-content;
    text-align: center;
    font-size: 24px;
    margin-left: auto;
    margin-right: auto;

    padding: 0 4px;
    line-height: 34.75px;

    border-bottom: medium solid ${colors.primary};
`;

const scheduleStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 328px;
`;

const listStyle = css`
    padding-left: 0;
    display: inline-block;
`;

const movieStyle = css`
    width: 100%;
    aspect-ratio: 560 / 315;
    height: auto;
`;

const itemStyle = css`
    text-align: left;
    margin-bottom: 0;
`;
