import { css } from '@emotion/react';
import { border, breakPoints } from '../../constants/constant';
import { colors } from '../../constants/colors';
export const imageBoxStyle = css`
    max-width: 600px;
    text-align: center;
    margin: 0 auto 10px;
`;

export const kitDescriptionBoxStyle = css`
    margin: 20px auto 40px;
    h3 {
        font-size: 24px;
        border-bottom: ${border} solid ${colors.primary};
        width: fit-content;
        padding: 0px 4px;
    }
    h4 {
        font-size: 22px;
        margin-bottom: 10px;
        margin-top: 20px;
        border-bottom: ${border} solid ${colors.text};
        width: fit-content;
        padding: 0px 4px;
    }
    h5 {
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 5px;
    }
    p {
        line-height: 24px;
        margin: 5px auto 5px;
    }
`;

export const youtubeWrapperStyle = css`
    width: fit-content;
    margin: auto;
`

export const youtubeBoxStyle = css`
    width: 560px;
    height: 315px;
    @media screen and (max-width: ${breakPoints.mobile}) {
        width: 320px;
        height: 180px;
    }
`;
