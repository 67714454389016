import { css } from '@emotion/react';
import React from 'react';
import TSUKUMO from '../../images/TSUKUMO.svg';

export const Sponsor = () => {
    return (
        <div css={style}>
            <a
                href="https://shop.tsukumo.co.jp/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={TSUKUMO} alt="TSUKUMO" css={imageStyle} />
            </a>
            <div css={sentenceStyle}>
                パソコン・パソコンパーツの専門店ツクモです。ツクモオリジナルPCの開発・販売のほか、各種VR機器を取り扱っております。
            </div>
        </div>
    );
};

const style = css`
    text-align: center;
`;

const imageStyle = css`
    width: 500px;
`;
const sentenceStyle = css`
    display: inline-block;
    text-align: left;
`;
