import React from 'react';
import { css } from '@emotion/react';
import { border, borderRadius } from '../../constants/constant';
import { colors } from '../../constants/colors';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';

export const LaboratoryCard = ({ title, path, introduction, time, image }) => {
    return (
        <a
            href={path}
            target="_blank"
            rel="noreferrer"
            css={[cardStyle, borderStyle]}
        >
            <div css={headImageStyle}>
                <div css={imageWrapStyle}>
                    <Carousel
                        showThumbs={false}
                        infiniteLoop={true}
                        autoPlay={true}
                        interval={5000}
                    >
                        {image.map((img, index) => (
                            <ImageContained
                                imageName={`laboratoryCard/${img}`}
                                alt={`laboratory${index}`}
                                key={index}
                            />
                        ))}
                    </Carousel>
                </div>
            </div>
            <div css={titleStyle}>{title}</div>
            <div css={introStyle}>{introduction}</div>
            <div css={timeStyle}>
                <FontAwesomeIcon icon={faCalendarDays}/>
                <p css={timeTextStyle}>{time}</p>
            </div>
        </a>
    );
};

const ImageContained = (props) => (
    <StaticQuery
        query={graphql`
            query {
                images: allFile {
                    edges {
                        node {
                            relativePath
                            name
                            childImageSharp {
                                gatsbyImageData(layout: FULL_WIDTH)
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => {
            const image = data.images.edges.find((n) => {
                return n.node.relativePath.includes(props.imageName);
            });
            if (!image) {
                return null;
            }
            return (
                <GatsbyImage
                    image={getImage(image.node)}
                    alt={props.alt}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    objectFit="contain"
                    backgroundColor="white"
                />
            );
        }}
    />
);

const borderStyle = css`
    border: ${border} solid ${colors.primary};
`;
const cardStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: between;
    text-align: center;
    border-radius: ${borderRadius};
    color: ${colors.white};
    text-decoration: none;
    background-color: ${colors.clickable};
    transition: 0.3s ease-in-out;
    &:hover {
        transform: scale(1.03);
    }
`;
const headImageStyle = css`
    width: 100%;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
    position: relative;
    &::before {
        content: '';
        padding-top: 100%;
        display: flex;
    }
`;

const imageWrapStyle = css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
`;

const titleStyle = css`
    font-size: 24px;
    font-weight: 400;
    line-height: 34.75px;
    margin: 4px 8px 0 8px;
`;

const introStyle = css`
    font-size: 16px;
    margin: 8px 8px 8px 8px;
    line-height: 23px;
`;

const timeStyle = css`
    display: flex;
    justify-content: center;
    align-content: center;
    margin: auto 8px 8px 8px;
`;

const timeTextStyle = css`
    margin-left: 4px;
    margin-bottom: 0;
    line-height: 16px;
`;