import React, { useState } from 'react';
import { css, keyframes } from '@emotion/react';
import { colors } from '../../constants/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHouse,
    faMapLocationDot,
    faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { MenuContent } from './MenuContent';
import { faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { SidebarButton } from './SidebarButton';

export const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <SidebarButton isOpen={isOpen} setIsOpen={setIsOpen} />
            <div css={isOpen ? visible : unvisible}>
                {isOpen && (
                    <>
                        {menuContentList.map((content) => (
                            <MenuContent
                                title={content.title}
                                link={content.link}
                                isExternal={content.isExternal}
                                isProject={content.isProject}
                                key={content.id}
                            >
                                {content.child}
                            </MenuContent>
                        ))}
                    </>
                )}
            </div>
        </>
    );
};

const menuContentList = [
    {
        id: 1,
        title: 'ホーム',
        link: '/',
        child: <FontAwesomeIcon icon={faHouse} />,
        isExternal: false,
        isProject: false,
    },
    {
        id: 2,
        title: '電子工作教室',
        link: '/electronic-workshop',
        child: <></>,
        isExternal: false,
        isProject: true,
    },
    {
        id: 3,
        title: 'プログラミング教室',
        link: '/programming-workshop',
        child: <></>,
        isExternal: false,
        isProject: true,
    },
    {
        id: 4,
        title: '謎解き',
        link: '/mystery',
        child: <></>,
        isExternal: false,
        isProject: true,
    },
    {
        id: 5,
        title: '配信企画',
        link: '/webcasting',
        child: <></>,
        isExternal: false,
        isProject: true,
    },
    {
        id: 6,
        title: '学生制作物展示',
        link: '/exhibition',
        child: <></>,
        isExternal: false,
        isProject: true,
    },
    {
        id: 7,
        title: '研究室展示',
        link: '/laboratory',
        child: <></>,
        isExternal: false,
        isProject: true,
    },
    {
        id: 8,
        title: 'アクセス',
        link: '/access',
        child: <FontAwesomeIcon icon={faMapLocationDot} />,
        isExternal: false,
        isProject: false,
    },
    {
        id: 9,
        title: '公式YouTube',
        link: 'https://www.youtube.com/channel/UCWPrxA-tgo7ePClMeZhMn0g',
        child: <FontAwesomeIcon icon={faYoutube} />,
        isExternal: true,
        isProject: false,
    },
    {
        id: 10,
        title: '公式Twitter',
        link: 'https://twitter.com/eeic_studentadv',
        child: <FontAwesomeIcon icon={faTwitter} />,
        isExternal: true,
        isProject: false,
    },
    {
        id: 11,
        title: '学科ホームページ',
        link: 'https://www.ee.t.u-tokyo.ac.jp/j/',
        child: <FontAwesomeIcon icon={faArrowUpRightFromSquare} />,
        isExternal: true,
        isProject: false,
    },
];

const sideBarOpenAnim = keyframes`
    from {
        width: 0px;
    }

    to {
        width: 80%;
    }
`;

const visible = css`
    background-color: ${colors.menu};
    z-index: 99;
    position: fixed;
    top: 0px;
    right: 0px;
    width: 80%;
    max-width: 320px;
    height: 100%;
    padding: 92px 0 0 0;
    border-right: 1px solid ${colors.white};
    color: ${colors.white};
    backdrop-filter: blur(4px);
    animation: ${sideBarOpenAnim} 0.5s linear 0s;
`;

const sideBarCloseAnim = keyframes`
    from {
        width: 80%;
    }

    to {
        width: 0px;
    }
`;

const unvisible = css`
    position: fixed;
    display: none;
    top: 0px;
    right: 0px;
    height: 100vh;
    width: 0px;
    margin-right: -10vh;
    background-color: ${colors.background};
    animation: ${sideBarCloseAnim} 0.5s linear 0s;
`;
