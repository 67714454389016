import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { css } from '@emotion/react';

import {
    imageBoxStyle,
    youtubeBoxStyle,
    youtubeWrapperStyle,
} from '../descriptionStyleCommon';

export const SwitchCircuit = () => {
    return (
        <>
            <h4>スイッチ回路</h4>
            <div css={youtubeWrapperStyle}>
                <iframe
                    css={youtubeBoxStyle}
                    src="https://www.youtube.com/embed/IOWqzfwaGE8"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
            <div css={switchImageBoxStyle}>
                <div css={[imageBoxStyle, imageMargin]}>
                    <StaticImage
                        src="../../../images/electronic-workshop/transistor/switch_circuit_diagram.png"
                        alt="switch cirtcuit diagram"
                        layout="constrained"
                        height={300}
                    />
                </div>
                <div css={[imageBoxStyle, imageMargin]}>
                    <StaticImage
                        src="../../../images/electronic-workshop/transistor/switch_circuit_breadboard.png"
                        alt="switch cirtcuit breadboard"
                        layout="constrained"
                        height={300}
                    />
                </div>
            </div>
            <p>
                この回路では、CdSセルと呼ばれる、周囲の明るさによって電気の流れやすさが変わる素子を用いて、
                トランジスタのベースに流れる電流を制御し、トランジスタをスイッチとして用います。
            </p>
            <p>
                暗いところでは、CdSセルの抵抗値は2MΩ程度で、主に抵抗2からトランジスタに電流が流れるため、
                トランジスタがオン状態になり、コレクタからエミッタに電流が流れてLEDが光ります。
            </p>
            <p>
                明るいところでは、CdSセルの抵抗値は数十kΩで、トランジスタに流れていた電流がCdSセルに流れるので、
                トランジスタはオフ状態になり、LEDは光りません。
            </p>
        </>
    );
};

const switchImageBoxStyle = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`;

const imageMargin = css`
    margin: 10px 20px;
`;
