import React from 'react';
import Layout from '../components/layout';
import { css } from '@emotion/react';
import { Seo } from '../components/Seo';
import { SectionEyecatch } from '../components/SectionEyecatch';
import { LinkButtonInternal } from '../components/LinkButton';

const NotFoundPage = () => (
    <Layout>
        <Seo title="404" />
        <SectionEyecatch pageTitle="404" pageSentences="NOT FOUND" />
        <div css={style}>
            <h3 css={h3Style}>NOT FOUND </h3>
            <p>このページは存在しません。</p>
            <div css={buttonAlignmentStyle}>
                <LinkButtonInternal title="トップページに戻る" path="/" />
            </div>
        </div>
    </Layout>
);

const style = css`
    text-align: center;
`;

const h3Style = css`
    margin-top: 32px;
`;

const buttonAlignmentStyle = css`
    display: inline-block;
`;

export default NotFoundPage;
