import React from 'react';
import { css } from '@emotion/react';

import { TruthTable } from './TruthTable';
import { breakPoints } from '../../../constants/constant';

export const CircuitDescription = ({
    circuitName,
    diagramImageComponent,
    wiringImageComponent,
    description,
    head,
    rows,
}) => {
    return (
        <div css={circuitDescriptionStyle}>
            <div css={descriptionBoxStyle}>
                <h5>{circuitName}</h5>
                <p>{description}</p>
            </div>
            <div css={diagramBoxStyle}>
                <div css={diagramBoxInnerStyle}>{diagramImageComponent}</div>
            </div>
            <div css={tableBoxStyle}>
                <div css={tableBoxInnerStyle}>
                    <TruthTable {...{ head, rows }} />
                </div>
            </div>
            <div css={wiringBoxStyle}>
                <div css={wiringBoxInnerStyle}>{wiringImageComponent}</div>
            </div>
        </div>
    );
};

const circuitDescriptionStyle = css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 2fr;
    margin: 40px auto 40px;
    @media screen and (max-width: ${breakPoints.mobile}) {
        display: block;
    }
`;

const descriptionBoxStyle = css`
    grid-column: 1 / 3;
    grid-row: 1 / 2;
`;

const diagramBoxStyle = css`
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    padding: 10px;
`;

const diagramBoxInnerStyle = css`
    margin-left: auto;
    margin-right: auto;
    max-width: 320px;
`;

const tableBoxStyle = css`
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    padding: 10px;
`;

const tableBoxInnerStyle = css`
    width: fit-content;
    margin: 0 auto;
`;

const wiringBoxStyle = css`
    grid-column: 3 / 4;
    grid-row: 1 / 3;
`;

const wiringBoxInnerStyle = css`
    width: 200px;
    margin: auto;
`;
