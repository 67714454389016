import * as React from 'react';
import { css } from '@emotion/react';
import Layout from '../components/layout';
import { Seo } from '../components/Seo';
import { colors } from '../constants/colors';
import { border } from '../constants/constant';
import { SectionEyecatch } from '../components/SectionEyecatch';
import { Image } from '../components/Image';
import { LinkButtonExternal } from '../components/LinkButton';
import { RecommendSection } from '../components/RecommendSection';

const imageName = 'eyecatch-mystery.png';
const MysteryPage = () => (
    <Layout>
        <Seo
            title="謎解き"
            pathname="/mystery"
            description="君の力で、謎解きAIロボットを完成させよう！"
        />
        <SectionEyecatch
            pageTitle="謎解き"
            pageSentences="君の力で、謎解きAIロボットを完成させよう！"
            imageName={imageName}
        />
        <div css={container}>
            <div css={containerBox}>
                <Image
                    imageName={imageName}
                    alt="programming workshop top image"
                />
            </div>
            <div css={containerBox}>
                <div css={introductionAlignmentStyle}>
                    <p css={introductionSentenceStyle}>ABOUT</p>
                    <p>
                        EEICのとある研究室。そこでは「謎解きAI」の研究が行われていた。
                        <br />
                        謎解きAIに興味をそそられ、研究室の扉を開けてみると・・・。
                    </p>
                </div>
                <div css={buttonAlignmentStyle}>
                    <LinkButtonExternal
                        title="あそぶ"
                        url="https://2022mystery.eeic.jp"
                    />
                </div>
            </div>
        </div>
        <RecommendSection recommendData={recommendData} />
    </Layout>
);

const recommendData = [
    {
        title: '電子工作教室',
        introduction: '身の回りの電子デバイスに詳しくなろう！',
        path: '/electronic-workshop',
    },
    {
        title: '配信企画',
        introduction: 'EEICのVTuber「みつえれ」を見に行こう！',
        path: '/webcasting',
    },
];

const container = css`
    display: flex;
    flex-wrap: wrap;
    margin: 32px 0px;
`;

const containerBox = css`
    flex-grow: 1;
    width: 300px;
    margin: 24px;
`;

const introductionAlignmentStyle = css`
    display: inline-block;
    text-align: left;
`;

const introductionSentenceStyle = css`
    display: flex;
    border-bottom: ${border} solid ${colors.primary};
    width: fit-content;
    font-size: 32px;
    line-height: 36px;
    padding: 0px 4px;
`;

const buttonAlignmentStyle = css`
    display: flex;
    flex-direction: column;
`;

export default MysteryPage;
