import * as React from 'react';
import { SectionEyecatch } from '../components/SectionEyecatch';

import Layout from '../components/layout';
import { Seo } from '../components/Seo';
import { ElectronicWorkshopMain } from '../components/electronicWorkshop/ElectronicWorkshopMain';
import { RecommendSection } from '../components/RecommendSection';

const ElectronicWorkshopPage = () => (
    <Layout>
        <Seo
            title="電子工作教室"
            pathname="/electronic-workshop"
            description="トランジスタ・論理ゲート・マイコンで楽しく遊んでみよう！"
        />
        <SectionEyecatch
            pageTitle="電子工作教室"
            pageSentences="トランジスタ・論理ゲート・マイコンで楽しく遊んでみよう！"
            imageName="electronic-workshop/eyecatch.jpg"
        />
        <ElectronicWorkshopMain />
        <RecommendSection recommendData={recommendData} />
    </Layout>
);

const recommendData = [
    {
        title: 'プログラミング教室',
        introduction: 'ブロックを並べてプログラミングをしてみよう！',
        path: '/programming-workshop',
    },
    {
        title: '学生制作物展示',
        introduction: '電子工作を使った作品も見てみよう！',
        path: '/exhibition',
    },
];

export default ElectronicWorkshopPage;
