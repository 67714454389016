import React, { useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { css } from '@emotion/react';
import Diode from '../images/diode.svg';
import { colors } from '../constants/colors';
import { borderRadius } from '../constants/constant';

const scrollToTop = () => {
    scroll.scrollToTop({ duration: 300, smooth: true });
};

export const PageTopButton = () => {
    const isBrowser = typeof window !== 'undefined';
    const isDocument = typeof document !== 'undefined';
    const [visible, setVisible] = useState(
        isBrowser &&
            window.scrollY > (2 * (isBrowser && window.innerHeight)) / 3
    );
    const [initState, setInitState] = useState(true);

    const changeState = () => {
        setVisible(
            isBrowser &&
                window.scrollY > (2 * (isBrowser && window.innerHeight)) / 3
        );
        if (visible) {
            setInitState(false);
        }
    };
    isDocument && document.addEventListener('scroll', changeState);

    return (
        <button
            onClick={scrollToTop}
            css={
                initState
                    ? initButtonStyle
                    : visible
                    ? buttonStyle
                    : hiddenButtonStyle
            }
        >
            <img css={logoStyle} src={Diode} alt="トップへ戻る" />
            <p css={textStyle}>TOP</p>
        </button>
    );
};

const initButtonStyle = css`
    opacity: 0;
`;

const buttonStyle = css`
    position: fixed;
    background-color: ${colors.boundaryWhite};
    inset: auto 16px 16px auto;
    width: 48px;
    height: 48px;
    border-radius: ${borderRadius};

    font-size: 10px;
    text-align: center;

    animation-name: zoomInAnime;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    @keyframes zoomInAnime {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`;

const hiddenButtonStyle = css`
    position: fixed;
    background-color: ${colors.boundaryWhite};
    inset: auto 16px 16px auto;
    width: 48px;
    height: 48px;
    border-radius: ${borderRadius};

    font-size: 10px;
    text-align: center;

    animation-name: zoomOutAnime;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    @keyframes zoomOutAnime {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }
`;

const logoStyle = css`
    position: relative;
    width: 16px;
    height: 30px;
    margin: 4px auto 14px;
`;

const textStyle = css`
    position: absolute;
    inset: 34px 0 0;
    margin: 0 auto 0;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 10px;
    color: ${colors.white};
    width: 21px;
    height: 14px;
`;
