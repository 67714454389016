import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import { colors } from '../constants/colors';
import { border, borderRadius } from '../constants/constant';

export const RecommendCard = ({ title, introduction, path }) => {
    return (
        <Link to={path} css={[borderStyle, cardStyle]}>
            <div css={titleStyle}>{title}</div>
            <div css={introStyle}>{introduction}</div>
        </Link>
    );
};

const borderStyle = css`
    border: ${border} solid ${colors.boundaryWhite};
`;
const cardStyle = css`
    display: block;
    text-align: center;
    border-radius: ${borderRadius};
    color: ${colors.white};
    text-decoration: none;
    background-color: ${colors.clickable};
    transition: 0.3s ease-in-out;
    &:hover {
        transform: scale(1.03);
    }
`;
const titleStyle = css`
    font-size: 24px;
    font-weight: 400;
    line-height: 34.75px;
    margin: 4px 8px 0 8px;
`;

const introStyle = css`
    font-size: 16px;
    margin: 8px;
    line-height: 23px;
`;
