import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
    kitDescriptionBoxStyle,
    youtubeBoxStyle,
    youtubeWrapperStyle,
} from '../descriptionStyleCommon';
import { SwitchCircuit } from './SwitchCircuit';
import { MelodyCircuit } from './MelodyCircuit';
import { Radio } from './Radio';
import { css } from '@emotion/react';

export const TransistorDescription = () => {
    return (
        <div css={kitDescriptionBoxStyle}>
            <h3>トランジスタ回路キット</h3>
            <h4>トランジスタとは</h4>
            <div css={transistorImageBox}>
                <div css={transistorImageStyle}>
                    <StaticImage
                        src="../../../images/electronic-workshop/transistor/transistor2.png"
                        alt="transistor"
                        layout="constrained"
                        height={115}
                    />
                </div>
                <div css={transistorImageStyle}>
                    <StaticImage
                        src="../../../images/electronic-workshop/transistor/NPN_bipolar_transistor.png"
                        alt="NPN bipolar transistor"
                        height={115}
                    />
                </div>
            </div>
            <p>
                トランジスタは、現代のありとあらゆる電子機器の根幹となるデバイスで、
                このキットではNPN型バイポーラトランジスタと呼ばれるものを使います。
            </p>
            <p>
                NPN型バイポーラトランジスタは、エミッタ・コレクタ・ベースの3つの端子を持ち、回路図上では次の記号であらわされます。
                このベースに電流が流れると、それに応じてコレクタからエミッタに大きな電流が流れるため、スイッチや電流増幅の役割を持ちます。
                さらに、トランジスタの交流に対する増幅特性によって、AM信号を復調することができ、これをトランジスタ検波と呼びます。
                このキットで、スイッチ・増幅・検波の3つのはたらきを実際に体験してください！
            </p>

            <div css={youtubeWrapperStyle}>
                <iframe
                    css={youtubeBoxStyle}
                    src="https://www.youtube.com/embed/FTcTdHOrrhQ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>

            <SwitchCircuit />

            <MelodyCircuit />

            <Radio />
        </div>
    );
};

const transistorImageBox = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 600px;
    margin: auto;
`;

const transistorImageStyle = css`
    margin: 5px;
`;
