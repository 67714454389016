import React from 'react';
import { css } from '@emotion/react';
import { Image } from '../Image';
import { border, borderRadius } from '../../constants/constant';
import { colors } from '../../constants/colors';

export const CraftKitCard = ({ introduction, headImage }) => {
    return (
        <div css={[cardStyle, borderStyle]}>
            <div css={headImageStyle}>
                <Image
                    imageName={headImage}
                    alt="project_head_image"
                    imgStyle={{
                        height: '144px',
                    }}
                />
            </div>
            <div css={introStyle}>{introduction}</div>
        </div>
    );
};

const borderStyle = css`
    border: ${border} solid ${colors.primary};
`;
const cardStyle = css`
    display: block;
    text-align: center;
    width: 253px;
    height: 210px;
    border-radius: ${borderRadius};
    color: ${colors.white};
    text-decoration: none;
    background-color: ${colors.clickable};
    margin: 10px;
    transition: 0.3s ease-in-out;
    &:hover {
        transform: scale(1.03);
    }
`;
const headImageStyle = css`
    width: 247px;
    height: 144px;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
`;
const introStyle = css`
    font-size: 20px;
    margin: 14px 8px 0 8px;
    line-height: 30px;
`;
