import * as React from 'react';
import { css } from '@emotion/react';
import { colors } from '../../constants/colors';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import { NavContent } from './NavContent';
import Logo from '../../images/logo_color.svg';

export const NavgationBlock = () => {
    return (
        <div css={contentsStyle}>
            <div css={[itemStyle, mainTitleBoxStyle]}>
                <Link to="/" css={mainTitleStyle}>
                    <img src={Logo} alt="ロゴ" css={logoStyle} />
                    近未来体験2022
                </Link>
            </div>
            <div css={linkBlockStyle}>
                <div css={itemStyle}>
                    <LinkList
                        title="ハイブリッド企画"
                        contents={[
                            {
                                id: 11,
                                title: '電子工作教室',
                                link: '/electronic-workshop',
                                child: <></>,
                                isExternal: false,
                                isProject: true,
                            },
                            {
                                id: 12,
                                title: 'プログラミング教室',
                                link: '/programming-workshop',
                                child: <></>,
                                isExternal: false,
                                isProject: true,
                            },
                            {
                                id: 13,
                                title: '学生制作物展示',
                                link: '/exhibition',
                                child: <></>,
                                isExternal: false,
                                isProject: true,
                            },
                        ]}
                    />
                </div>
                <div css={itemStyle}>
                    <LinkList
                        title="オンライン企画"
                        contents={[
                            {
                                id: 21,
                                title: '謎解き',
                                link: '/mystery',
                                child: <></>,
                                isExternal: false,
                                isProject: true,
                            },
                            {
                                id: 22,
                                title: '配信企画',
                                link: '/webcasting',
                                child: <></>,
                                isExternal: false,
                                isProject: true,
                            },
                            {
                                id: 23,
                                title: '研究室展示',
                                link: '/laboratory',
                                child: <></>,
                                isExternal: false,
                                isProject: true,
                            },
                        ]}
                    />
                </div>
                <div css={itemStyle}>
                    <LinkList
                        title="SNS"
                        contents={[
                            {
                                id: 31,
                                title: 'EEIC学生広報',
                                link: 'https://twitter.com/eeic_studentadv',
                                child: <FontAwesomeIcon icon={faTwitter} />,
                                isExternal: true,
                                isProject: false,
                            },
                            {
                                id: 32,
                                title: 'みつえれ',
                                link: 'https://twitter.com/mitsuele_eeic',
                                child: <FontAwesomeIcon icon={faTwitter} />,
                                isExternal: true,
                                isProject: false,
                            },
                            {
                                id: 33,
                                title: 'みつえれ',
                                link: 'https://www.youtube.com/channel/UCWPrxA-tgo7ePClMeZhMn0g',
                                child: <FontAwesomeIcon icon={faYoutube} />,
                                isExternal: true,
                                isProject: false,
                            },
                        ]}
                    />
                </div>
                <div css={itemStyle}>
                    <LinkList
                        title="アクセス"
                        contents={[
                            {
                                id: 41,
                                title: 'アクセス',
                                link: '/access',
                                child: (
                                    <FontAwesomeIcon icon={faMapLocationDot} />
                                ),
                                isExternal: false,
                                isProject: false,
                            },
                        ]}
                    />
                </div>
                <div css={itemStyle}>
                    <LinkList
                        title="過去のページ"
                        contents={[
                            {
                                id: 51,
                                title: '近未来体験2021',
                                link: 'https://2021.eeic.jp/',
                                child: <></>,
                                isExternal: true,
                                isProject: false,
                            },
                            {
                                id: 52,
                                title: '近未来体験2020',
                                link: 'https://2020.eeic.jp/',
                                child: <></>,
                                isExternal: true,
                                isProject: false,
                            },
                            {
                                id: 53,
                                title: '近未来体験2019',
                                link: 'https://2019.eeic.jp',
                                child: <></>,
                                isExternal: true,
                                isProject: false,
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

const LinkList = ({ title, contents }) => {
    return (
        <>
            <div css={titleStyle}>{title}</div>
            <ul css={linkListStyle}>
                {contents.map((content) => (
                    <NavContent
                        key={content.id}
                        title={content.title}
                        link={content.link}
                        isExternal={content.isExternal}
                        isProject={content.isProject}
                    >
                        {content.child}
                    </NavContent>
                ))}
            </ul>
        </>
    );
};

const contentsStyle = css`
    display: block;
    @media (min-width: 1332px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

const itemStyle = css`
    flex-basis: 186px;
`;

const mainTitleBoxStyle = css`
    text-align: center;
    display: flex;
    align-items: center;
    margin: 0 auto;
    @media only screen and (min-width: 230px) {
        width: 226px;
    }
    @media only screen and (min-width: 420px) {
        width: 372px;
    }
    @media only screen and (min-width: 600px) {
        width: 558px;
    }
    @media only screen and (min-width: 790px) {
        width: 744px;
    }
    @media only screen and (min-width: 980px) {
        width: 930px;
    }
    @media only screen and (min-width: 1332px) {
        width: 186px;
        margin-right: 0;
    }
`;

const linkBlockStyle = css`
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    @media only screen and (min-width: 230px) {
        width: 186px;
    }
    @media only screen and (min-width: 420px) {
        width: 372px;
    }
    @media only screen and (min-width: 600px) {
        width: 558px;
    }
    @media only screen and (min-width: 790px) {
        width: 744px;
    }
    @media only screen and (min-width: 980px) {
        width: 930px;
    }
`;

const mainTitleStyle = css`
    font-size: 24px;
    white-space: nowrap;
    text-decoration: none;
    color: ${colors.white};
    display: flex;
    align-items: center;
`;

const logoStyle = css`
    height: 48px;
    margin: 0px;
    padding-right: 4px;
`;

const titleStyle = css`
    color: ${colors.footerMenuTitle};
`;

const linkListStyle = css`
    margin: 0;
`;
