import React from 'react';
import { css } from '@emotion/react';

import { border } from '../../../constants/constant';
import { colors } from '../../../constants/colors';

export const TruthTable = ({ head, rows }) => {
    return (
        <div css={tableBoxStyle}>
            <table css={tableStyle}>
                <thead>
                    <tr>
                        {head.map((element, index) => (
                            <th key={index}>{element}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((element, index) => (
                                <td key={index}>{element}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const tableBoxStyle = css`
    padding: 0 12px 0 0;
    border: ${border} solid ${colors.boundaryWhite};
    width: fit-content;
    height: fit-content;
`;

const tableStyle = css`
    margin: 5px;
    table-layout: auto;
    th {
        font-size: 18px;
    }
    td {
        font-size: 16px;
    }
`;
