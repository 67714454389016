import * as React from 'react';
import { Hero } from '../components/index/hero';
import { ContentSection } from '../components/ContentSection';
import { MayFes } from '../components/index/MayFes';
import { TwitterTimeline } from '../components/TwitterTimeline';
import { Access } from '../components/index/Access';
import { Goods } from '../components/index/Goods';
import { Sponsor } from '../components/index/Sponsor';
import Layout from '../components/layout';
import { Seo } from '../components/Seo';
import { ProjectCardList } from '../components/index/ProjectCardList';
import { SpecialNews } from '../components/index/SpecialNews';

const IndexPage = () => (
    <Layout>
        <Seo
            keywords={[
                `EEIC`,
                `電子情報工学科`,
                `電気電子工学科`,
                `東京大学`,
                `工学部`,
                `電気系`,
                `電子情報機器学`,
                `電子工作`,
                `プログラミング`,
                `VTuber`,
                `バーチャルYouTuber`,
                `御城みつき`,
                `風里えれな`,
                `VR`,
                `AR`,
                `mitsuele_eeic`,
                `eeic_studentadv`,
            ]}
            pathname="/"
        />
        <Hero />
        <ContentSection title="特報">
            <SpecialNews />
        </ContentSection>
        <ContentSection title="企画一覧">
            <ProjectCardList />
        </ContentSection>
        <ContentSection title="お知らせ">
            <TwitterTimeline userId="eeic_studentadv" />
        </ContentSection>
        <ContentSection title="アクセス">
            <Access />
        </ContentSection>
        <ContentSection title="グッズ販売">
            <Goods />
        </ContentSection>
        <ContentSection title="キャンパスへの入構を希望する方へ">
            <MayFes />
        </ContentSection>
        <ContentSection title="協力企業様">
            <Sponsor />
        </ContentSection>
    </Layout>
);

export default IndexPage;
