import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// GatsbyImageのWrapperで
// svgには対応していないのでsvgを表示する場合にはimgタグを使う

export const Image = (props) => (
    <StaticQuery
        query={graphql`
            query {
                images: allFile {
                    edges {
                        node {
                            relativePath
                            name
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => {
            const image = data.images.edges.find((n) => {
                return n.node.relativePath.includes(props.imageName);
            });
            if (!image) {
                return null;
            }
            return (
                <GatsbyImage
                    image={getImage(image.node)}
                    alt={props.alt}
                    imgStyle={props.imgStyle}
                />
            );
        }}
    />
);
