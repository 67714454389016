import React from 'react';
import { css } from '@emotion/react';
import { ExhibitionDetail } from './ExhibitionDetail';
import exhibitionData from './exhibitionPageData.json';
import { ContentSection } from '../ContentSection';
import { border } from '../../constants/constant';
import { colors } from '../../constants/colors';

export const ExhibitionDetailGroup = () => {
    return (
        <div className="project-groups">
            {Object.keys(exhibitionData).map((key, index) => {
                return (
                    <ContentSection title={key} key={"content-"+key}>
                        {exhibitionData[key]['data'].map((project, index2) => (
                            <div key={"div-"+key + String(index2)}>
                                <ExhibitionDetail
                                    data={project}
                                    id={key + String(index2)}
                                    key={"exd" + key + String(index2)}
                                />
                                {index ===
                                Object.keys(exhibitionData).length - 1 ? (
                                    index2 ===
                                    exhibitionData[key]['data'].length -
                                        1 ? null : (
                                        <hr
                                            css={hrStyle}
                                            key={"hr"+key + String(index2)}
                                        ></hr>
                                    )
                                ) : (
                                    <hr
                                        css={hrStyle}
                                        key={"hr"+key + String(index2)}
                                    ></hr>
                                )}
                            </div>
                        ))}
                    </ContentSection>
                );
            })}
        </div>
    );
};

const hrStyle = css`
    margin: 32px auto 0px;
    width: 95%;
    height: ${border};
    background-color: ${colors.boundaryWhite};
    border: none;
`;
