import * as React from 'react';
import { css } from '@emotion/react';
import { border, borderRadius } from '../../constants/constant';
import { colors } from '../../constants/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

const RelatedLink = ({ link, title, child }) => {
    return (
        <div css={style}>
            <a
                href={link}
                target="_blank"
                className="exit"
                rel="noreferrer"
                css={buttonStyle}
            >
                {title}
                <FontAwesomeIcon icon={child} css={iconStyle} />
            </a>
        </div>
    );
};

export const RelatedURL = () => {
    return (
        <div css={relatedUrlStyle}>
            {contents.map((content) => (
                <RelatedLink
                    link={content.link}
                    title={content.title}
                    child={content.child}
                    key={content.key}
                />
            ))}
        </div>
    );
};

const contents = [
    {
        key: 1,
        title: '第95回五月祭サイト',
        link: 'https://gogatsusai.jp/95/visitor/',
        child: faUpRightFromSquare,
    },
    {
        key: 2,
        title: '近未来体験HP',
        link: 'https://2022.eeic.jp/',
        child: faUpRightFromSquare,
    },
    {
        key: 3,
        title: '近未来体験Twitter',
        link: 'https://twitter.com/eeic_studentadv',
        child: faTwitter,
    },
];

const style = css`
    text-align: center;
    transition-duration: 0.2s;
    width: fit-content;
    &:hover {
        transform: scale(1.1);
    }
`;

const buttonStyle = css`
    border-radius: ${borderRadius};
    border: ${border} solid ${colors.primary};
    padding: 8px;
    color: ${colors.white};
    text-decoration: none;
    background-color: ${colors.clickable};
`;

const iconStyle = css`
    margin-left: 8px;
`;

const relatedUrlStyle = css`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;
`;
