import * as React from 'react';
import { ContentSection } from '../ContentSection';
import { WebcastingAbout } from './WebcastingAbout';
import { WebcastingSNS } from './WebcastingSNS';
import { TimeSchedule } from './TimeSchedule';
import { SelfIntroduction } from './SelfIntroduction';
import { RelatedURL } from './RelatedURL';
import { EEICTerm } from './EEICTerm';

export const WebcastingDetail = () => {
    return (
        <>
            <WebcastingAbout />
            <WebcastingSNS />
            <ContentSection title="配信タイムスケジュール">
                <TimeSchedule />
            </ContentSection>
            <ContentSection title="自己紹介">
                <SelfIntroduction />
            </ContentSection>
            <ContentSection title="関連URL">
                <RelatedURL />
            </ContentSection>
            <ContentSection title="EEIC用語">
                <EEICTerm />
            </ContentSection>
        </>
    );
};
