export const colors = {
    background: '#002836',
    boundaryWhite: 'rgba(255, 255, 255, 0.16)',
    clickable: '#00324B',
    footerBackground: '#00222F',
    footerMenuTitle: 'rgba(255, 255, 255, 0.6)',
    label1: '#FEB3EB',
    label2: '#B3EBFE',
    menu: 'rgba(0, 40, 54, 0.92)',
    primary: '#ECF160',
    text: 'rgba(255, 255, 255, 0.87)',
    white: '#FFFFFF',
};
