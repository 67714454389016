import * as React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { colors } from '../constants/colors';
import BulbWhite from '../images/bulb-text-color.svg';

export const ContentSection = ({ title, children }) => {
    return (
        <div css={sectionStyle}>
            <div css={sectionHeaderWrapStyle}>
                <div css={sectionHeaderStyle}>
                    <img
                        src={BulbWhite}
                        alt="BulbWhite"
                        css={sectionBulbStyle}
                    />
                    <p css={sectionTitleStyle}>{title}</p>
                </div>
            </div>
            {children}
        </div>
    );
};

const sectionStyle = css`
    padding: 32px 0;
`;

const sectionBulbStyle = css`
    margin: 0px;
`;

const sectionHeaderWrapStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
`;

const sectionHeaderStyle = css`
    display: flex;
    border-bottom: medium solid ${colors.text};
    width: fit-content;
    padding: 0px 4px;
`;

const sectionTitleStyle = css`
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    margin: 0px 0px 0px 13px;
`;

ContentSection.propTypes = {
    title: PropTypes.string,
};
