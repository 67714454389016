import React from 'react';
import { css } from '@emotion/react';
import { RecommendCard } from './RecommendCard';
import { ContentSection } from './ContentSection';
import { breakPoints } from '../constants/constant';

export const RecommendSection = ({ recommendData }) => {
    return (
        <ContentSection title="おすすめ企画">
            <div css={recommendSectionStyle}>
                {recommendData.map((data, index) => {
                    return (
                        <RecommendCard
                            title={data.title}
                            introduction={data.introduction}
                            path={data.path}
                            key={index}
                        />
                    );
                })}
            </div>
        </ContentSection>
    );
};

const recommendSectionStyle = css`
    margin-top: 32px;
    display: grid;
    gap: 32px;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 240px);
    @media screen and (max-width: ${breakPoints.mobile}) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 0.8fr));
    } ;
`;
