import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { imageBoxStyle, youtubeBoxStyle, youtubeWrapperStyle } from '../descriptionStyleCommon';
import { css } from '@emotion/react';
import { breakPoints } from '../../../constants/constant';

export const MelodyCircuit = () => {
    return (
        <>
            <h4>メロディ回路</h4>
            <div css={youtubeWrapperStyle}>
                <iframe
                    css={youtubeBoxStyle}
                    src="https://www.youtube.com/embed/1GcPKcxCrF0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
            <div css={imageLayoutStyle}>
                <div css={imageLayoutInner}>
                    <div css={[imageBoxStyle, imageAdditionalStyle]}>
                        <StaticImage
                            src="../../../images/electronic-workshop/transistor/melody_IC1_diagram.png"
                            alt="melody IC 1 diagram"
                            height={200}
                        />
                    </div>
                </div>
                <div css={imageLayoutInner}>
                    <div css={[imageBoxStyle, imageAdditionalStyle]}>
                        <StaticImage
                            src="../../../images/electronic-workshop/transistor/melody_IC2_diagram.png"
                            alt="melody IC 2 diagram"
                            height={200}
                        />
                    </div>
                </div>
                <div css={imageLayoutInner}>
                    <div css={[imageBoxStyle, imageAdditionalStyle]}>
                        <StaticImage
                            src="../../../images/electronic-workshop/transistor/melody_IC3_diagram.png"
                            alt="melody IC 3 diagram"
                            height={200}
                        />
                    </div>
                </div>
            </div>
            <div css={imageLayoutStyle}>
                <div css={imageLayoutInner}>
                    <div css={[imageBoxStyle, imageAdditionalStyle]}>
                        <StaticImage
                            src="../../../images/electronic-workshop/transistor/melody_IC1_breadboard.png"
                            alt="melody IC 1 breadboard"
                            height={180}
                        />
                    </div>
                </div>
                <div css={imageLayoutInner}>
                    <div css={[imageBoxStyle, imageAdditionalStyle]}>
                        <StaticImage
                            src="../../../images/electronic-workshop/transistor/melody_IC2_breadboard.png"
                            alt="melody IC 2 breadboard"
                            height={180}
                        />
                    </div>
                </div>
                <div css={imageLayoutInner}>
                    <div css={[imageBoxStyle, imageAdditionalStyle]}>
                        <StaticImage
                            src="../../../images/electronic-workshop/transistor/melody_IC3_breadboard.png"
                            alt="melody IC 3 breadboard"
                            height={180}
                        />
                    </div>
                </div>
            </div>
            <p>
                この回路では、メロディICと呼ばれる3本の端子を持つ素子を用います。
                メロディICは、回路図上の1・2番の端子の間に電圧をかけると、3番の端子から音声信号が流れる素子で、
                実際に電池・メロディIC・スピーカーを1番目の回路図の様につないでみると、スピーカーから小さめの音で曲が流れます。
            </p>
            <p>
                次に、トランジスタでこの音声信号を増幅してみましょう。
                2番目の回路では、メロディICで発生した音声信号がトランジスタのベースに流れ込み、
                コレクタからエミッタに増幅された音声信号が流れます。
                そのため、スピーカーをトランジスタのコレクタにつなげると、先ほどよりも大きな音で曲が流れます。
            </p>
            <p>
                さらに、もう１つトランジスタを追加してみましょう。
                3番目の回路では、1つ目のトランジスタのエミッタに2つ目のトランジスタのベースを接続します。
                こうすることで、増幅された信号がもう1回増幅され、さらに大きな音声信号がスピーカーに流れます。
                このように、トランジスタ2つを用いて非常に大きな増幅率を得る構成を「ダーリントン接続」と呼びます。
            </p>
        </>
    );
};

const imageLayoutStyle = css`
    @media screen and (min-width: ${breakPoints.mobile}) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
`;

const imageLayoutInner = css`
    @media screen and (min-width: ${breakPoints.mobile}) {
        padding: auto;
        width: calc(100% / 3);
    }
`;

const imageAdditionalStyle = css`
    margin-left: 10px;
    margin-right: 10px;
    @media screen and (max-width: ${breakPoints.mobile}) {
        margin: 10px auto;
        max-width: 320px;
    }
`;
