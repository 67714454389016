import { css } from '@emotion/react';
import React from 'react';
import { colors } from '../../constants/colors';
import { borderRadius, border } from '../../constants/constant';

export const BottomButton = ({ text }) => {
    return <div css={buttonStyle}>{text}</div>;
};

const buttonStyle = css`
    border: ${border} solid ${colors.primary};
    border-radius: ${borderRadius};
    border-color: ${colors.primary};
    display: block;
    text-align: center;
    width: 253px;
    height: 60px;
    color: ${colors.white};
    text-decoration: none;
    background-color: ${colors.clickable};
    margin: 10px;
    transition: 0.3s ease-in-out;
    &:hover {
        transform: scale(1.03);
    }
    font-size: 20px;
    line-height: 30px;
    padding-top: 10px;
`;
