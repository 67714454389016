import { css } from '@emotion/react';
import React from 'react';
import { Image } from '../Image';

export const SpecialNews = () => {
    return (
        <div css={style}>
            <div css={sentenceStyle}>
                <p>
                    第95回五月祭総選挙で「アカデミック部門第2位」を獲得しました！展示をお楽しみくださった皆様、ありがとうございました！
                </p>
            </div>
            <Image imageName="specialNews.png" />
        </div>
    );
};

const style = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const sentenceStyle = css`
    display: inline-block;
    text-align: left;
`;
