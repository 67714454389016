import * as React from 'react';
import { css } from '@emotion/react';

export const EEICTerm = () => {
    return (
        <div
            css={{
                textAlign: 'center',
                display: 'block',
            }}
        >
            <ul css={style}>
                <li css={itemStyle}>
                    EEICは東京大学工学部電子情報工学科・電気電子工学科の二つの学科の略で、
                    物理と情報を極めることができる学科です。
                </li>
                <li css={itemStyle}>
                    近未来体験はEEICが五月祭で企画したもので、
                    プログラミング教室や電子工作教室などいろんな出し物があります！
                </li>
            </ul>
        </div>
    );
};

const style = css`
    padding-left: 0;
    display: inline-block;
`;

const itemStyle = css`
    text-align: left;
    margin-bottom: 0;
`;
