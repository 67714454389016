import * as React from 'react';
import { css } from '@emotion/react';
import { Image } from '../Image';
import { colors } from '../../constants/colors';
import { border } from '../../constants/constant';

export const WebcastingAbout = () => {
    return (
        <div css={aboutBlockStyle}>
            <div css={imageStyle}>
                <Image imageName="eyecatch-webcasting.png" alt="eyecatch" />
            </div>
            <div css={discriptionStyle}>
                <div css={aboutStringStyle}>ABOUT</div>
                <div>
                    配信企画では、EEICの妖精「みつき」と「えれな」がEEICならではの動画をYouTubeで配信していきます！
                    <br />
                    『みつえれチャンネル』のチャンネル登録をお願いします！
                    <br />
                    五月祭当日には電気電子工学科と電子情報工学科の先生方をお招きして、生配信を行います！
                    <br />
                    ぜひチェックしてください！
                </div>
            </div>
        </div>
    );
};

const aboutBlockStyle = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;

    padding-top: 32px;
`;

const imageStyle = css`
    width: 348px;
`;

const discriptionStyle = css`
    display: block;
    flex-basis: 600px;
`;

const aboutStringStyle = css`
    width: fit-content;
    padding: 0 4px;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    border-bottom: ${border} solid ${colors.primary};
`;
