import React from 'react';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { Image } from '../../components/Image';
import { border, borderRadius } from '../../constants/constant';
import { colors } from '../../constants/colors';

export const ExhibitionCard = ({ title, path, introduction, headImage }) => {
    return (
        <Link to={path} css={[cardStyle, borderStyle]}>
            <div css={headImageStyle}>
                <Image
                    imageName={headImage}
                    alt="project_head_image"
                    imgStyle={{
                        height: '144px',
                        borderRadius: '5px 5px 0 0'
                    }}
                />
            </div>
            <div css={titleStyle}>{title}</div>
            <div css={introStyle}>{introduction}</div>
        </Link>
    );
};

const borderStyle = css`
    border: ${border} solid ${colors.primary};
`;
const cardStyle = css`
    display: flex;
    flex-flow: column;
    text-align: center;
    width: 253px;
    height: 285px;
    border-radius: ${borderRadius};
    color: ${colors.white};
    text-decoration: none;
    background-color: ${colors.clickable};
    transition: 0.3s ease-in-out;
    &:hover {
        transform: scale(1.03);
    }
`;
const headImageStyle = css`
    width: calc(253px - ${border} * 2);
    height: 144px;
    border-radius: 5px 5px 0 0;
`;

const titleStyle = css`
    font-size: 24px;
    font-weight: 400;
    line-height: 34.75px;
`;

const introStyle = css`
    font-size: 16px;
`;
