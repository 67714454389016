import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { css } from '@emotion/react';

import { breakPoints, dropShadow } from '../../constants/constant';
import { colors } from '../../constants/colors';

export const Hero = () => {
    const { heroImagePC, heroImageMobile, heroImageTablet } = useStaticQuery(
        graphql`
            query {
                heroImagePC: file(relativePath: { eq: "hero-image.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                        )
                    }
                }
                heroImageMobile: file(
                    relativePath: { eq: "hero-image-mobile.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                        )
                    }
                }
                heroImageTablet: file(
                    relativePath: { eq: "hero-image-tablet.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                        )
                    }
                }
            }
        `
    );

    const backGroundImagePC = convertToBgImage(getImage(heroImagePC));
    const backGroundImageMobile = convertToBgImage(getImage(heroImageMobile));
    const backGroundImageTablet = convertToBgImage(getImage(heroImageTablet));

    const innerText = (
        <div css={heroInner}>
            <div css={logoSpace}></div>
            <div>
                <div css={titleArea}>
                    <div css={logoSpaceTablet}></div>
                    <div css={titleSpaceTablet}>
                        <h2 css={titleLittle}>EEIC 学科展示</h2>
                        <h1 css={title}>近未来体験2022</h1>
                    </div>
                </div>
                <div css={description}>
                    <p>
                        東京大学工学部電子情報工学科
                        <br css={onMobile} />
                        ・電気電子工学科
                        <br css={onPC} />
                        (EEIC)の学生
                        <br css={onMobile} />
                        による五月祭企画です。
                        <br />
                        EEICの魅力が詰まった企画が
                        <br css={onMobile} />
                        盛りだくさん。
                        <br />
                        ぜひ、「近未来」を
                        <br css={onMobile} />
                        体験してください！
                    </p>
                    <p css={removeMarginBottom}>
                        【日時】
                        <br />
                        2022年5月14日(土)・15日(日) 9:00〜18:00
                        <br />
                        【会場】
                        <br />
                        東京大学本郷キャンパス
                        <br css={onMobile} />
                        工学部2号館 <br css={onMobile} />/ 本サイト
                        <br />
                        <span css={descriptionSmall}>
                            ※入構は完全事前予約制です
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );

    return (
        <section css={heroSection}>
            <div css={boxHeroMobile}>
                <BackgroundImage {...backGroundImageMobile}>
                    {innerText}
                </BackgroundImage>
            </div>
            <div css={boxHeroTablet}>
                <BackgroundImage {...backGroundImageTablet}>
                    {innerText}
                </BackgroundImage>
            </div>
            <div css={boxHeroPC}>
                <BackgroundImage
                    {...backGroundImagePC}
                    preserveStackingContext
                    css={backgroundBlur}
                >
                    <div css={background}>
                        <BackgroundImage {...backGroundImagePC}>
                            {innerText}
                        </BackgroundImage>
                    </div>
                </BackgroundImage>
            </div>
        </section>
    );
};

const heroSection = css`
    width: 100vw;
    margin: 0 calc(50% - 50vw);
`;

const boxHeroMobile = css`
    display: none;
    @media screen and (max-width: ${breakPoints.mobile}) {
        display: block;
    }
`;

const boxHeroTablet = css`
    display: none;
    @media screen and (max-width: ${breakPoints.tablet}) {
        display: block;
    }
    @media screen and (max-width: ${breakPoints.mobile}) {
        display: none;
    } ;
`;

const boxHeroPC = css`
    @media screen and (max-width: ${breakPoints.tablet}) {
        display: none;
    }
`;

const heroInner = css`
    height: 100%;
    display: grid;
    grid-template-columns: 516px 1fr;
    @media screen and (max-width: ${breakPoints.tablet}) {
        display: block;
        text-align: center;
        margin: auto;
        max-width: 528px;
    }
    @media screen and (max-width: ${breakPoints.mobile}) {
        max-width: 360px;
    }
`;

const logoSpace = css`
    @media screen and (max-width: ${breakPoints.mobile}) {
        height: 272px;
    }
`;

const logoSpaceTablet = css`
    @media screen and (max-width: ${breakPoints.tablet}) {
        @media not screen and (max-width: ${breakPoints.mobile}) {
            min-height: 250px;
            width: 160px;
        }
    }
`;

const titleSpaceTablet = css`
    @media screen and (max-width: ${breakPoints.tablet}) {
        @media not screen and (max-width: ${breakPoints.mobile}) {
            min-height: 250px;
            width: 350px;
        }
    }
`;

const background = css`
    max-width: ${breakPoints.pc};
    margin: 0 auto;
    z-index: 1;
`;

const backgroundBlur = css`
    :after {
        filter: blur(10px);
    }
`;

const titleArea = css`
    @media screen and (max-width: ${breakPoints.tablet}) {
        display: flex;
        justify-content: center;
    }
    @media screen and (max-width: ${breakPoints.mobile}) {
        display: block;
    } ;
`;

const heroTextPadding = 0;
const titleLittle = css`
    font-family: 'vdl-logojr', 'M PLUS 1p', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 1.2;
    color: ${colors.white};
    text-shadow: ${dropShadow};
    margin-top: 108px;
    @media screen and (max-width: ${breakPoints.mobile}) {
        margin-top: 16px;
    }
    margin-bottom: 0px;
    padding: ${heroTextPadding};
`;

const title = css`
    font-family: 'vdl-logojr', 'M PLUS 1p', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 56px;
    line-height: 1.2;
    color: ${colors.white};
    text-shadow: ${dropShadow};
    margin-top: 4px;
    margin-bottom: 20px;
    padding: ${heroTextPadding};
`;

const description = css`
    padding: ${heroTextPadding};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.5;
    color: ${colors.white};
    text-shadow: ${dropShadow};
    text-align: left;
    @media screen and (max-width: ${breakPoints.mobile}) {
        text-align: center;
    }
`;

const descriptionSmall = css`
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 1.2;
`;

const removeMarginBottom = css`
    margin-bottom: 0;
`;

const onMobile = css`
    display: none;
    @media screen and (max-width: ${breakPoints.mobile}) {
        display: inline; // 初期値
    }
`;

const onPC = css`
    @media screen and (max-width: ${breakPoints.tablet}) {
        display: none;
    }
`;
