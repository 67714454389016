import { css } from '@emotion/react';
import React from 'react';
import { LinkButtonInternal } from '../LinkButton';

export const Access = () => {
    return (
        <div css={style}>
            <div css={sentenceStyle}>
                キャンパスへの行き方は
                <span css={buttonStyle}>
                    <LinkButtonInternal title="アクセス" path="/access" />
                </span>
                をご覧ください。
            </div>
        </div>
    );
};

const style = css`
    text-align: center;
`;

const buttonStyle = css`
    display: inline-block;
    margin: 0 8px;
`;

const sentenceStyle = css`
    display: inline-block;
    text-align: left;
`;
