module.exports = {
    siteMetadata: {
        title: `近未来体験2022`,
        description: `『近未来体験2022』は電子情報工学科・電気電子工学科の学生が主催する五月祭企画です。`,
        author: `東京大学電気系学科（EEIC）学生広報`,
        authorTwitter: `@eeic_studentadv`,
        siteUrl: `https://2022.eeic.jp`,
    },
    plugins: [
        `gatsby-plugin-emotion`,
        `gatsby-plugin-sitemap`,
        `gatsby-plugin-react-helmet`,
        `gatsby-plugin-image`,
        {
            resolve: `gatsby-source-filesystem`,
            options: {
                name: `images`,
                path: `${__dirname}/src/images`,
            },
        },
        `gatsby-transformer-sharp`,
        `gatsby-plugin-sharp`,
        {
            resolve: `gatsby-plugin-manifest`,
            options: {
                name: `近未来体験2022`,
                short_name: `近未来体験2022`,
                start_url: `/`,
                background_color: `#002836`,
                // This will impact how browsers show your PWA/website
                // https://css-tricks.com/meta-theme-color-and-trickery/
                // theme_color: `#ECF160`,
                display: `minimal-ui`,
                icon: `src/images/bulb-icon.png`, // This path is relative to the root of the site.
            },
        },
        {
            resolve: `gatsby-plugin-canonical-urls`, // URLの正規化
            options: {
                siteUrl: `https://2022.eeic.jp`,
                stripQueryString: true,
            },
        },
        // this (optional) plugin enables Progressive Web App + Offline functionality
        // To learn more, visit: https://gatsby.dev/offline
        // `gatsby-plugin-offline`,
    ],
};
