import React from 'react';
import { css } from '@emotion/react';

import { TruthTable } from './TruthTable';
import { borderRadius, breakPoints } from '../../../constants/constant';

export const GateDescription = ({
    gateName,
    imageComponent,
    description,
    head,
    rows,
}) => {
    return (
        <div css={gateDescriptionStyle}>
            <div css={descriptionBoxStyle}>
                <h5>{gateName}</h5>
                <p>{description}</p>
            </div>
            <div css={imageBoxStyle}>
                <div css={imageBoxInnerStyle}>{imageComponent}</div>
            </div>
            <div css={truthTableBoxStyle}>
                <TruthTable {...{ head, rows }} />
            </div>
        </div>
    );
};

const gateDescriptionStyle = css`
    margin: 40px auto 40px;
    display: grid;
    grid-template-columns: 2fr minmax(200px, 1fr) minmax(250px, 1fr);
    justify-content: space-between;
    @media screen and (max-width: ${breakPoints.mobile}) {
        display: block;
    }
`;

const descriptionBoxStyle = css`
    padding-right: 50px;
`;

const imageBoxStyle = css`
    text-align: center;
`;

const imageBoxInnerStyle = css`
    border-radius: ${borderRadius};
    margin: 20px auto 0;
    width: 200px;
`;

const truthTableBoxStyle = css`
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
`;
