/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Header from './header';
import { Footer } from './footer';
import './layout.css';
import { MayFesAwards } from './MayFesAwards';
import { PageTopButton } from './PageTopButton';

const Layout = ({ children }) => {
    return (
        <>
            <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                    crossorigin
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=M+PLUS+1&family=M+PLUS+1p&display=swap"
                    rel="stylesheet"
                />
                <script>
                    {`
                    (function(d) {
                        var config = {
                            kitId: 'lrh8kns',
                            scriptTimeout: 3000,
                            async: true
                        },
                        h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
                        })(document);
                    `}
                </script>
                <script
                    async
                    src="https://www.googletagmanager.com/gtag/js?id=UA-228719747-1"
                ></script>
                <script>
                    {`
                    window.dataLayer = window.dataLayer || []; function gtag()
                    {dataLayer.push(arguments)}; gtag('js', new Date());
                    gtag('config', 'UA-228719747-1');
                `}
                </script>
            </Helmet>
            <Header />
            <div className="wrapper">
                <main>{children}</main>
                <MayFesAwards />
            </div>
            <PageTopButton />
            <Footer />
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
