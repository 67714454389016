import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { imageBoxStyle, youtubeBoxStyle, youtubeWrapperStyle } from '../descriptionStyleCommon';

export const Radio = () => {
    return (
        <>
            <h4>AMラジオ</h4>
            <div css={youtubeWrapperStyle}>
                <iframe
                css={youtubeBoxStyle}
                    src="https://www.youtube.com/embed/doV0JhdyXC4"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
            <div css={imageBoxStyle}>
                <StaticImage
                    src="../../../images/electronic-workshop/transistor/AM_radio_circuit_diagram.png"
                    alt="AM radio diagram"
                />
            </div>
            <div css={imageBoxStyle}>
                <StaticImage
                    src="../../../images/electronic-workshop/transistor/AM_radio_breadboard.png"
                    alt="AM radio breadboard"
                />
            </div>
            <p>
                この回路は、①共振アンテナ、②検波、③増幅の３つの部分に分けられます。それぞれの役割と作り方を見ていきましょう。
            </p>
            <h5>①共振アンテナ</h5>
            <p>
                共振アンテナ部分はアンテナとマイクロインダクタとバリコンから成ります。まず共振について説明します。
            </p>
            <p>
                この回路における共振とは、「インダクタ」と「コンデンサ」によって、特定の「周波数」の信号だけを増幅させることです。
                ここでの周波数とは、この回路では文化放送というラジオ局の1134kHzです。
                なお、バリコンとは容量を調整可能なコンデンサであり、
                ダイヤルを回すことで文化放送の周波数にぴったり合わせられるようになっています。
            </p>
            <p>
                アンテナからの信号はラジオの信号と雑音とが混ざっていますが、共振によってラジオの信号だけを増幅して取り出すことができます。
            </p>
            <p>
                マイクロインダクタとバリコンとアンテナを図のようにつなぐだけで完成です。
            </p>
            <h5>②検波</h5>
            <p>
                AMラジオの信号というのは、図のようになっています。1134
                kHzの信号の強さが、人の声である100 ~ 1000
                Hzあたりで振動しています。AMラジオの信号から100 ~ 1000
                Hzあたりの信号を取り出すのが、この検波部分です。
            </p>
            <div css={imageBoxStyle}>
                <StaticImage
                    src="../../../images/electronic-workshop/transistor/AM_signal.png"
                    alt="AM signal waveform"
                />
            </div>
            <p>
                コンデンサを一つ挿したら、そこにダーリントン接続したトランジスタをつなげましょう。
                ラジオの信号に対して音声信号は微弱ですから、ダーリントン接続で大きく増幅します。
                そこに並列につながるコンデンサは、「平滑」作用を持ちます。平滑とは、変化を緩やかにすることです。
                AMラジオの信号は先述のように、とても速く振動する波の振幅が緩やかに振動することでできています。
                速く振動する波を平滑することで、緩やかな音声信号を取り出しています。
            </p>
            <h5>③増幅</h5>
            <p>
                この増幅回路は、検波回路から来た音声信号をイヤホンで聞き取れる音量にするためのものです。
            </p>
            <p>
                ベースに信号が入力されると、コレクタとエミッタに大きな信号が流れます。
                これを２段重ねにして十分大きな信号にしてからイヤホンジャックに渡しています。
            </p>
            <p>
                トランジスタ３のコレクタをトランジスタ４のベースに、
                トランジスタ４のエミッタをイヤホンジャックに接続することに注意して組み立ててください。
            </p>
            <p>
                最後に、電池ボックスと電圧安定化のためのコンデンサを２つつけて完成です。
            </p>
        </>
    );
};
