import * as React from 'react';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../constants/colors';
import { Link } from 'gatsby';

const NavContentText = ({ title, isProject, children }) => {
    return (
        <>
            <FontAwesomeIcon
                icon={faAnglesRight}
                css={
                    isProject
                        ? [navContentAngleStyle, navContentAngleYellowStyle]
                        : navContentAngleStyle
                }
            />
            <div css={navContentTextStyle}>{title}</div>
            <div>{children}</div>
        </>
    );
};

export const NavContent = ({
    title,
    link,
    children,
    isExternal,
    isProject,
}) => {
    return (
        <>
            {isExternal ? (
                <a
                    css={navContentAStyle}
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                >
                    <NavContentText {...{ title, isProject }}>
                        {children}
                    </NavContentText>
                </a>
            ) : (
                <Link css={navContentLinkStyle} to={link}>
                    <NavContentText {...{ title, isProject }}>
                        {children}
                    </NavContentText>
                </Link>
            )}
        </>
    );
};

const navContentAngleStyle = css`
    margin-left: 8px;
`;

const navContentAngleYellowStyle = css`
    color: ${colors.primary};
`;

const navContentTextStyle = css`
    padding: 0px;
    margin-left: 8px;
    margin-right: 12px;
`;

const navContentAStyle = css`
    text-decoration: none;
    color: ${colors.white};
    display: flex;
    align-items: center;
    margin: 12px 0px;
    &:hover {
        background-color: ${colors.clickable};
    }
`;

const navContentLinkStyle = css`
    text-decoration: none;
    color: ${colors.white};
    display: flex;
    align-items: center;
    margin: 12px 0px;
    &:hover {
        background-color: ${colors.clickable};
    }
`;
