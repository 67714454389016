import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { css } from '@emotion/react';
import { TwitterTimelineEmbed, TwitterShareButton } from 'react-twitter-embed';
import { timelineSize } from '../constants/constant';
import { colors } from '../constants/colors';
import { border, borderRadius } from '../constants/constant';

export const TwitterTimeline = ({ userId }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    siteUrl
                }
            }
        }
    `);

    return (
        <div>
            <div css={borderStyle}>
                <div css={twitterTimelineEmbedStyle}>
                    <TwitterTimelineEmbed
                        onLoad={function noRefCheck() {}}
                        options={{
                            height: timelineSize.height,
                            width: timelineSize.width,
                        }}
                        placeholder="Loading..."
                        theme="dark"
                        transparent="true"
                        screenName={userId}
                        sourceType="profile"
                    />
                </div>
            </div>

            <div css={twitterShareButtonEmbedStyle}>
                <TwitterShareButton
                    onLoad={function noRefCheck() {}}
                    tag="近未来体験2022"
                    url={data.siteUrl}
                    options={{
                        text: '『近未来体験2022』を応援しよう！',
                        size: 'large',
                        hashtags: [
                            '近未来体験2022',
                            'eeic',
                            '五月祭',
                            'mayfes',
                        ],
                    }}
                    placeholder="Loading..."
                />
            </div>
        </div>
    );
};

const borderStyle = css`
    display: flex;
    justify-content: center;
`;

const twitterTimelineEmbedStyle = css`
    display: flex;
    justify-content: center;
    padding: 8px;
    border: ${border} solid ${colors.boundaryWhite};
    border-radius: ${borderRadius};
    background-color: ${colors.clickable};
`;

const twitterShareButtonEmbedStyle = css`
    display: flex;
    justify-content: center;
    padding: 8px;
`;
