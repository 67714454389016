import React from 'react';
import { ProjectCard } from '../ProjectCard';
import { css } from '@emotion/react';
import projectCardData from './projectCardData';

export const ProjectCardList = () => {
    return (
        <div css={flexStyle}>
            {projectCardData.data.map((project) => (
                <ProjectCard
                    title={project.title}
                    path={project.path}
                    introduction={project.introduction}
                    time={project.time}
                    headImage={project.headImage}
                    projectMode={project.projectMode}
                    projectSpan={project.projectSpan}
                    key={project.title}
                />
            ))}
        </div>
    );
};

const flexStyle = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    column-gap: 32px;
    row-gap: 32px;
`;
