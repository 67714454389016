import * as React from 'react';
import { css } from '@emotion/react';
import { colors } from '../constants/colors';
import { Link } from 'gatsby';
import { Sidebar } from './header/Sidebar';
import { HeaderContent } from './header/HeaderContent';
import { useLocation } from '@reach/router';
import Logo from '../images/logo_color.svg';

const Header = () => {
    const location = useLocation();
    return (
        <header css={headerStyle}>
            <div css={headerBodyStyle}>
                <div>
                    <Link to="/" css={headerLinkStyle}>
                        <img src={Logo} alt="ロゴ" css={logoStyle} />
                        近未来体験2022
                    </Link>
                </div>
                <div css={headerContentListStyle}>
                    {headerContentList.map((content) => (
                        <HeaderContent
                            title={content.title}
                            link={content.link}
                            currentPath={location.pathname}
                            key={content.id}
                        />
                    ))}
                </div>
            </div>
            <div css={sidebarStyle}>
                <Sidebar />
            </div>
        </header>
    );
};

const headerContentList = [
    {
        id: 1,
        title: '電子工作教室',
        link: '/electronic-workshop',
    },
    {
        id: 2,
        title: 'プログラミング教室',
        link: '/programming-workshop',
    },
    {
        id: 3,
        title: '謎解き',
        link: '/mystery',
    },
    {
        id: 4,
        title: '配信企画',
        link: '/webcasting',
    },
    {
        id: 5,
        title: '学生制作物展示',
        link: '/exhibition',
    },
    {
        id: 6,
        title: '研究室展示',
        link: '/laboratory',
    },
];

const headerStyle = css`
    height: 56px;
    padding: 0 16px;
    background-color: ${colors.menu};
    position: fixed;
    width: 100%;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const headerBodyStyle = css`
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
`;

const headerLinkStyle = css`
    color: ${colors.white};
    text-decoration: none;
    font-size: 16px;
    display: flex;
    white-space: nowrap;
    align-items: center;
`;

const breakPoint = '840px';

const headerContentListStyle = css`
    display: flex;
    font-size: 16px;
    @media (max-width: ${breakPoint}) {
        display: none;
    }
`;

const sidebarStyle = css`
    display: none;
    @media (max-width: ${breakPoint}) {
        display: block;
    }
`;

const logoStyle = css`
    height: 32px;
    margin: 0px;
    padding-right: 4px;
`;

export default Header;
