import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { imageBoxStyle, kitDescriptionBoxStyle } from '../descriptionStyleCommon';

export const MicroComputerDescription = () => {
    return (
        <div css={kitDescriptionBoxStyle}>
            <h3>マイコンプログラミングキット</h3>
            <p>
                ※このキットは本郷キャンパス来場者限定であり、オンライン販売はありません。ご了承ください。
            </p>
            <p>
                マイコンと呼ばれる小さなコンピューターに、自分が書いたプログラムを書き込むことで、思い通りにLEDを光らせたり、音を鳴らしたりすることができます。
            </p>
            <p>
                プログラミング未経験者、初心者でも楽しめるよう、分かりやすいビジュアルプログラミング言語を用います。
            </p>
            <div css={imageBoxStyle}>
                <StaticImage
                    src="../../../images/electronic-workshop/micro-computer/code_ex.png"
                    alt="visual programming code example"
                />
            </div>
            <div css={imageBoxStyle}>
                <StaticImage
                    src="../../../images/electronic-workshop/micro-computer/micon1.jpeg"
                    alt="micro computer kit image 1"
                />
            </div>
            <div css={imageBoxStyle}>
                <StaticImage
                    src="../../../images/electronic-workshop/micro-computer/micon2.jpeg"
                    alt="micro computer kit image 2"
                />
            </div>
            <div css={imageBoxStyle}>
                <StaticImage
                    src="../../../images/electronic-workshop/micro-computer/micon3.jpeg"
                    alt="micro computer kit image 3"
                />
            </div>
        </div>
    );
};
