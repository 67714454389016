import * as React from 'react';
import { css } from '@emotion/react';
import Layout from '../components/layout';
import { Seo } from '../components/Seo';
import { colors } from '../constants/colors';
import { border } from '../constants/constant';
import { SectionEyecatch } from '../components/SectionEyecatch';
import { Image } from '../components/Image';
import { LinkButtonExternal } from '../components/LinkButton';
import { RecommendSection } from '../components/RecommendSection';

const ProgrammingWorkshopPage = () => (
    <Layout>
        <Seo
            title="プログラミング教室"
            pathname="/programming-workshop"
            description="プログラミングを学べるゲームを作成・展示しています！"
        />
        <SectionEyecatch
            pageTitle="プログラミング教室"
            pageSentences="プログラミングを学べるゲームを作成・展示しています。"
            imageName="programming-workshop/introducing-image-for-programming-workshop.png"
        />
        <div css={container}>
            <div css={containerBox}>
                <Image
                    imageName="programming-workshop/introducing-image-for-programming-workshop.png"
                    alt="programming workshop top image"
                />
            </div>
            <div css={containerBox}>
                <div css={introductionAlignmentStyle}>
                    <p css={introductionSentenceStyle}>ABOUT</p>
                    <p>
                        ゲームで遊びながら楽しくプログラミングの考え方を勉強しましょう。ステージをすべてクリアすると何かが起こるかも？！
                        <br />
                        今年はステージ投稿機能も追加しました。みんなで盛り上がりましょう。
                    </p>
                </div>
                <div css={buttonAlignmentStyle}>
                    <LinkButtonExternal
                        title="あそぶ"
                        url="https://2022programming-workshop.eeic.jp/"
                    />
                </div>
            </div>
        </div>
        <RecommendSection recommendData={recommendData} />
    </Layout>
);

const recommendData = [
    {
        title: '謎解き',
        introduction: '君の力で謎解きAIロボットを完成させよう！',
        path: '/mystery',
    },
    {
        title: '研究室展示',
        introduction: 'プログラミングを使った研究紹介も見てみよう！',
        path: '/laboratory',
    },
];

const container = css`
    display: flex;
    flex-wrap: wrap;
    margin: 32px 0px;
`;

const containerBox = css`
    flex-grow: 1;
    width: 300px;
    margin: 24px;
`;

const introductionAlignmentStyle = css`
    display: inline-block;
    text-align: left;
`;

const introductionSentenceStyle = css`
    display: flex;
    border-bottom: ${border} solid ${colors.primary};
    width: fit-content;
    font-size: 32px;
    line-height: 36px;
    padding: 0px 4px;
`;

const buttonAlignmentStyle = css`
    display: inline-block;
`;

export default ProgrammingWorkshopPage;
