import React from 'react';
import { ExhibitionCard } from './ExhibitionCard';
import { css } from '@emotion/react';
import exhibitionData from './exhibitionPageData.json';
import { ContentSection } from '../ContentSection';

export const ExhibitionGroup = () => {
    return (
        <div css={containerStyle}>
            {Object.keys(exhibitionData).map((key) => {
                return (
                    <ContentSection title={key} key={"content-"+key}>
                        <p css={sentenceStyle} key={"p-"+key}>
                            {exhibitionData[key]['description']}
                        </p>
                        <div css={flexStyle} key={"div"+key}>
                            {exhibitionData[key]['data'].map(
                                (project, index) => (
                                    <ExhibitionCard
                                        title={project.title}
                                        path={
                                            '/exhibition#' +
                                            String(key) +
                                            String(index)
                                        }
                                        introduction={project.about_short}
                                        headImage={project.img[0]}
                                        key={String(key) + String(index)}
                                    />
                                )
                            )}
                        </div>
                    </ContentSection>
                );
            })}
        </div>
    );
};

const containerStyle = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 32px;
    text-align: center;
    width: 100%;
    column-gap: 32px;
`;

const sentenceStyle = css`
    display: inline-block;
    text-align: center;
    margin-bottom: 32px;
`;

const flexStyle = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    column-gap: 32px;
    row-gap: 32px;
`;
