import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { breakPoints } from '../../../constants/constant';

import {
    kitDescriptionBoxStyle,
    imageBoxStyle,
    youtubeWrapperStyle,
    youtubeBoxStyle,
} from '../descriptionStyleCommon';
import { CircuitDescription } from './CircuitDescription';
import { GateDescription } from './GateDescription';

export const LogicCircuitDescription = () => {
    return (
        <div css={kitDescriptionBoxStyle}>
            <h3>論理回路キット</h3>
            <div css={youtubeWrapperStyle}>
                <iframe
                    css={youtubeBoxStyle}
                    src="https://www.youtube.com/embed/FsKOeb0-h3Q"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
            <div css={youtubeWrapperStyle}>
                <iframe
                    css={youtubeBoxStyle}
                    src="https://www.youtube.com/embed/FTcTdHOrrhQ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
            <div css={youtubeWrapperStyle}>
                <iframe
                    css={youtubeBoxStyle}
                    src="https://www.youtube.com/embed/dShH4hHNxZA"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
            <h4>キット概要</h4>
            <p>
                身の回りにあるコンピュータは、すべての情報を０と１の列で表現しています。
                そしてその情報を計算したり、記憶したりすることで動いているのです。
                様々なことができるコンピュータですが、
                その基本は下で説明するような論理ゲートを組み合わせて作られています。
            </p>
            <h4>STEP1. 各論理ゲートの動作を知ろう</h4>
            {gateDescriptions.map((element, index) => (
                <GateDescription key={index} {...element} />
            ))}
            <p>
                それでは、実際に回路を組んでいきます。今回用いる論理ゲートのICの型番、
                内部の配線は次のようになっています。
            </p>
            <div css={gateICGridStyle}>
                <div css={gateICBox}>
                    <StaticImage
                        src="../../../images/electronic-workshop/logic-circuit/step1/74hc00.png"
                        alt="nand gate diagram"
                        height={220}
                    />
                </div>
                <div css={gateICBox}>
                    <StaticImage
                        src="../../../images/electronic-workshop/logic-circuit/step1/74hc02.png"
                        alt="nor gate diagram"
                        height={220}
                    />
                </div>
                <div css={gateICBox}>
                    <StaticImage
                        src="../../../images/electronic-workshop/logic-circuit/step1/74hc04.png"
                        alt="not gate diagram"
                        height={220}
                    />
                </div>
                <div css={gateICBox}>
                    <StaticImage
                        src="../../../images/electronic-workshop/logic-circuit/step1/74hc08.png"
                        alt="and gate diagram"
                        height={220}
                    />
                </div>
                <div css={gateICBox}>
                    <StaticImage
                        src="../../../images/electronic-workshop/logic-circuit/step1/74hc32.png"
                        alt="or gate diagram"
                        height={220}
                    />
                </div>
                <div css={gateICBox}>
                    <StaticImage
                        src="../../../images/electronic-workshop/logic-circuit/step1/74hc86.png"
                        alt="xor gate diagram"
                        height={220}
                    />
                </div>
            </div>
            <p>
                ブレッドボードに配線図のように配線して、各論理ゲートの
                働きを確かめてみましょう。
            </p>
            <p>
                以降、入力については「スイッチを押した状態」の時に1、
                「スイッチを押していない状態」の時に0が入力され、
                出力については1の時にLEDが点灯、0の時にLEDが消灯します。
            </p>
            <div css={imageBoxStyle}>
                <StaticImage
                    src="../../../images/electronic-workshop/logic-circuit/step1/step1_kairo.png"
                    alt="step1 circuit diagram"
                />
            </div>
            <div css={wiringsBoxStyle}>
                <div css={wiringImageStyle}>
                    <StaticImage
                        src="../../../images/electronic-workshop/logic-circuit/step1/and_haisen.jpg"
                        alt="and gate wiring"
                        height={500}
                    />
                    <p>AND, OR, NAND, EXORゲート</p>
                </div>
                <div css={wiringImageStyle}>
                    <StaticImage
                        src="../../../images/electronic-workshop/logic-circuit/step1/nor_haisen.jpg"
                        alt="nor gate wiring"
                        height={500}
                    />
                    <p>NORゲート</p>
                </div>
                <div css={wiringImageStyle}>
                    <StaticImage
                        src="../../../images/electronic-workshop/logic-circuit/step1/not_haisen.jpg"
                        alt="not gate wiring"
                        height={500}
                    />
                    <p>NOTゲート</p>
                </div>
            </div>

            <h4>STEP2. 応用的な回路を作ろう</h4>

            <p>
                すべての入力から出力を出す回路は上のゲートの内NOT、AND、ORの
                ３種類さえあれば作ることができます。また、NANDは１種類のみで
                すべての回路を作ることができます。
            </p>
            <p>
                この章では、キットで作ることのできる様々な機能を
                持つ回路を紹介していきます。
                回路図の入力部分、出力部分を省略していますが、
                STEP1の最後の回路と同様にスイッチ、LED、抵抗を配線しています。
                ブレッドボード配線図では省略していません。
            </p>
            {circuitDescriptions.map((element, index) => (
                <CircuitDescription key={index} {...element} />
            ))}
        </div>
    );
};

const wiringsBoxStyle = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
    margin-top: 15px;
`;

const wiringImageStyle = css`
    margin: 0px 10px;
    width: 225px;
`;

const gateICGridStyle = css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: ${breakPoints.mobile}) {
        display: block;
    }
`;

const gateICBox = css`
    margin: 10px auto;
    padding: 0px 10px;
    text-align: center;
`;

const gateDescriptions = [
    {
        gateName: 'NOT',
        imageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step1/not.png"
                layout="fullWidth"
                alt="not gate"
            />
        ),
        gateImageName: 'not.png',
        description: (
            <>
                入力と出力が反対になるゲートです。
                <br />
                表は入力と出力の組み合わせを表しています。
            </>
        ),
        head: ['入力A', '出力Y'],
        rows: [
            [0, 1],
            [1, 0],
        ],
    },
    {
        gateName: 'AND',
        imageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step1/and.png"
                layout="fullWidth"
                alt="and gate"
            />
        ),
        description: (
            <>
                入力AとBがともに１のときのみ１を出力するゲートです。
                それ以外の場合は０を出力します。
            </>
        ),
        head: ['入力A', '入力B', '出力Y'],
        rows: [
            [0, 0, 0],
            [0, 1, 0],
            [1, 0, 0],
            [1, 1, 1],
        ],
    },
    {
        gateName: 'OR',
        imageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step1/or.png"
                layout="fullWidth"
                alt="or gate"
            />
        ),
        description: (
            <>
                入力AとBの内、どちらか一方でも１であれば１を出力します。
                両方とも０の場合は出力０です。
            </>
        ),
        head: ['入力A', '入力B', '出力Y'],
        rows: [
            [0, 0, 0],
            [0, 1, 1],
            [1, 0, 1],
            [1, 1, 1],
        ],
    },
    {
        gateName: 'NAND',
        imageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step1/nand.png"
                layout="fullWidth"
                alt="nand gate"
            />
        ),
        description: <>出力がANDゲートのものと反対となるゲートです。</>,
        head: ['入力A', '入力B', '出力Y'],
        rows: [
            [0, 0, 1],
            [0, 1, 1],
            [1, 0, 1],
            [1, 1, 0],
        ],
    },
    {
        gateName: 'NOR',
        imageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step1/nor.png"
                layout="fullWidth"
                alt="nor gate"
            />
        ),
        description: <>出力がORゲートのものと反対となるゲートです。</>,
        head: ['入力A', '入力B', '出力Y'],
        rows: [
            [0, 0, 1],
            [0, 1, 0],
            [1, 0, 0],
            [1, 1, 0],
        ],
    },
    {
        gateName: 'EXOR',
        imageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step1/xor.png"
                layout="fullWidth"
                alt="xor gate"
            />
        ),
        description: (
            <>
                入力のどちらか一方のみが１のときに１を出力するゲートです。それ以外の場合は０を出力します。
            </>
        ),
        head: ['入力A', '入力B', '出力Y'],
        rows: [
            [0, 0, 0],
            [0, 1, 1],
            [1, 0, 1],
            [1, 1, 0],
        ],
    },
];

const circuitDescriptions = [
    {
        circuitName: '多数決回路',
        diagramImageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step2/tasu_kairo.png"
                alt="tasuketsu diagram"
            />
        ),
        wiringImageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step2/tasu_haisen.jpg"
                alt="tasuketsu wiring"
            />
        ),
        description: (
            <>
                その名の通り、出てくる結果は3つの入力による多数決になります。つまり、1よりも0の方が多く入ると0が出てきて、0よりも1の方が多く入ると1が出てきます。
            </>
        ),
        head: ['3つの入力(A, B, C)のうち......', '出力Y'],
        rows: [
            ['全部0', '0'],
            ['一つが1で、残り二つが0', '0'],
            ['二つが1で、残り一つが0', '1'],
            ['全部1', '1'],
        ],
    },
    {
        circuitName: '2ビットエンコーダ',
        diagramImageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step2/encoder_kairo.png"
                alt="2 bit encoder diagram"
            />
        ),
        wiringImageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step2/encoder_haisen.jpg"
                alt="2 bit encoder wiring"
            />
        ),
        description: <>10進数の入力を2進数に変換します。</>,
        head: ['どの入力をつけるか', '2進数で表すと...(LEDが光るかどうか)'],
        rows: [
            ['1番', '01(片方だけ光る)'],
            ['2番', '10(1番と逆側が光る)'],
            ['3番', '11(両方光る)'],
        ],
    },
    {
        circuitName: '半加算器(ハーフアダー)',
        diagramImageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step2/hadder_kairo.png"
                alt="half adder diagram"
            />
        ),
        wiringImageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step2/hadder_haisen.jpg"
                alt="half adder wiring"
            />
        ),
        description: (
            <>
                2進数で1桁の足し算ができます。出力の左側はくり上がりの有無を、右側は足した結果その桁に現れる結果を表します。10進数で例えるなら、5+7=12という計算においてAは5、Bは7、Cは十の位の1、Sは一の位の2となります。
            </>
        ),
        head: [
            '入力A + 入力B',
            '出力C(繰り上がりの有無)',
            '出力S(その桁に残る数字)',
        ],
        rows: [
            ['0 + 0', '0', '0'],
            ['0 + 1', '0', '1'],
            ['1 + 0', '0', '1'],
            ['1 + 1', '1', '0'],
        ],
    },
    {
        circuitName: '全加算器(フルアダー)',
        diagramImageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step2/fadder_kairo.png"
                alt="full adder diagram"
            />
        ),
        wiringImageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step2/fadder_haisen.jpg"
                alt="full adder wiring"
            />
        ),
        description: (
            <>
                半加算器の強化版みたいなもので、全加算器を組み合わせることで2進数で何桁にもわたる計算が可能になります。
                Cinは下の桁からのくり上がりを、Sはその位に残る数字を、Coutはその桁で出たくり上がりを表します。
            </>
        ),
        head: ['A, B, Cinのうち', 'Cout(くり上がり)', 'S(その桁に残る数字)'],
        rows: [
            ['全部0', '0', '0'],
            ['一つが1、二つが0', '0', '1'],
            ['二つが1、一つが0', '1', '0'],
            ['全部1', '1', '1'],
        ],
    },
    {
        circuitName: 'SRラッチ',
        diagramImageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step2/srlatch_kairo.png"
                alt="rs latch diagram"
            />
        ),
        wiringImageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step2/srlatch_haisen.jpg"
                alt="rs latch wiring"
            />
        ),
        description: (
            <>
                ラッチ(latch)は英語で「かんぬき」という意味ですが、その名の通りSRラッチは入力がないときに出力を一定に保つことができます。Sはセットを、Rはリセットを意味しています。
                <br />
                Sのみ１のとき出力Qを１に設定し、Rのみ１のとき出力Qを０に設定します。S、Rがともに０のときは値を保存します。
                <br />
                また、これ以降の回路に出てくるQの上に線が引いてあるものは、Qの反転、すなわち、QのLEDが光っているなら消え、消えているなら光ります。
            </>
        ),
        head: ['入力S', '入力R', '出力Q'],
        rows: [
            ['0', '0', 'そのままの値'],
            ['0', '1', '0'],
            ['1', '0', '1'],
        ],
    },
    {
        circuitName: 'Dラッチ',
        diagramImageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step2/dlatch_kairo.png"
                alt="D latch diagram"
            />
        ),
        wiringImageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step2/dlatch_haisen.jpg"
                alt="D latch wiring"
            />
        ),
        description: (
            <>
                Eが1の時だけDの値が出力に素通りし、Eが0になるとその瞬間の値が出力に保持されます。
            </>
        ),
        head: ['入力E', '出力Q'],
        rows: [
            ['0', '直前と変わらない'],
            ['0', 'Dと同じ'],
        ],
    },
    {
        circuitName: 'Dフリップフロップ',
        diagramImageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step2/dff_kairo.png"
                alt="D flip flop diagram"
            />
        ),
        wiringImageComponent: (
            <StaticImage
                src="../../../images/electronic-workshop/logic-circuit/step2/dff_haisen.jpg"
                alt="D flip flop wiring"
            />
        ),
        description: (
            <>
                clkが1になった瞬間のDの値が出力に出ます。それ以外の瞬間は出力は以前の値のまま変わりません。
                <br />
                ※初版の配線図・回路図もDフリップフロップとして動作しますが、出力値が反転しており、
                表の説明と異なっておりました。申し訳ありません。現在の配線図・回路図では修正されています。
            </>
        ),
        head: ['CLK', '出力Q'],
        rows: [
            ['0→1に変化する瞬間', 'その瞬間のDの値'],
            ['それ以外の時', '直前の値のまま変わらない'],
        ],
    },
];
