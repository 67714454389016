import * as React from 'react';
import { SectionEyecatch } from '../components/SectionEyecatch';
import { css } from '@emotion/react';
import Layout from '../components/layout';
import { Seo } from '../components/Seo';
import { LinkButtonExternal } from '../components/LinkButton';
import { LaboratoryCard } from '../components/laboratory/LaboratoryCard';
import { RecommendSection } from '../components/RecommendSection';

const LaboratoryPage = () => {
    return (
        <Layout>
            <Seo
                title="研究室展示"
                pathname="/laboratory"
                description="配信企画に参加する研究室をご紹介します。"
            />
            <SectionEyecatch pageTitle="研究室展示" />
            <div css={laboratoryTextStyle}>
                EEICの研究室とみつえれの対談企画が進行中！配信時刻をチェックしよう！合わせて、EEICに関する研究室について情報がまとめられた
                <span css={laboratoryButtonStyle}>
                    <LinkButtonExternal
                        title="電気の回廊"
                        url="http://nanotechnet.t.u-tokyo.ac.jp/kairo/"
                    />
                </span>
                も、ぜひご覧ください。
            </div>
            <div css={laboratoryListStyle}>
                {LaboratoryList.map((lab, index) => (
                    <LaboratoryCard
                        title={lab.name}
                        introduction={lab.introduction}
                        time={lab.time}
                        image={lab.image}
                        key={index}
                    />
                ))}
            </div>
            <RecommendSection recommendData={recommendData} />
        </Layout>
    );
};

const LaboratoryList = [
    {
        name: '江崎・落合研',
        path: 'http://www.hongo.wide.ad.jp/',
        introduction:
            '国境なきサイバー攻撃〜開発途上国のITインフラが狙われる〜',
        time: '5/14 12:00〜13:00',
        image: ['ochiai.png'],
    },
    {
        name: '藤本・清水・藤田研',
        path: 'https://hflab.edu.k.u-tokyo.ac.jp/',
        introduction: '制御の力で世界が変わる！無線給電',
        time: '5/14 13:00〜14:00',
        image: ['fujimoto.png'],
    },
    {
        name: 'アイン研',
        path: 'https://www.anh-lab.com/ja',
        introduction: '半導体はまだまだ面白い、そして熱い！',
        time: '5/14 14:00〜15:00',
        image: ['anh.jpg'],
    },
    {
        name: '相澤研',
        path: 'http://www.hal.t.u-tokyo.ac.jp/lab/ja/index_1.xhtml',
        introduction: '映像による街のバーチャルな探訪',
        time: '5/15 12:00〜13:00',
        image: ['aizawa.jpeg'],
    },
    {
        name: '森川・成末研',
        path: 'https://www.mlab.t.u-tokyo.ac.jp/',
        introduction: 'デジタルテクノロジーで未来を創る',
        time: '5/15 13:00〜14:00',
        image: [
            'morikawa2.png',
            'narusue2.jpeg',
            'morikawa.jpg',
            'narusue.jpg',
        ],
    },
    {
        name: '松井研',
        path: 'http://www.hal.t.u-tokyo.ac.jp/lab/ja/index_1.xhtml',
        introduction: '画像検索エンジンをライブコーディングするってよ',
        time: '5/15 14:00〜15:00',
        image: ['matsui.png'],
    },
];

const recommendData = [
    {
        title: '配信企画',
        introduction: '研究室の方々とみつえれの配信をチェック！',
        path: '/webcasting',
    },
    {
        title: '学生制作物展示',
        introduction: '講義で生まれた作品を見てみよう！',
        path: '/exhibition',
    },
];

const laboratoryTextStyle = css`
    display: inline-block;
    margin-top: 32px;
`;

const laboratoryButtonStyle = css`
    display: inline-block;
    margin: 8px;
`;

const laboratoryListStyle = css`
    margin: 32px auto;
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(3, minmax(148px, 1fr));
    @media screen and (max-width: 540px) {
        grid-template-columns: repeat(auto-fit, minmax(148px, 1fr));
    }
`;

export default LaboratoryPage;
