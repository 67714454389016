import React from 'react';
import { LinkButtonExternal } from '../LinkButton';

export const BaseLinkButton = () => {
    return (
        <LinkButtonExternal
            title="ご購入はこちらから"
            url="https://mayfes2022.base.shop/"
        />
    );
};
