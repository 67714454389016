import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';

import { CraftKitCard } from './CraftKitCard';
import { LogicCircuitDescription } from './logicCircuit/LogicCircuitDescription';
import { TransistorDescription } from './transistor/TransistorDescription';
import { MicroComputerDescription } from './microComputer/MicroComputerDescription';
import { BottomButton } from './BottomButton';
import { BaseLinkButton } from './BaseLinkButton';
import { breakPoints } from '../../constants/constant';

export const ElectronicWorkshopMain = () => {
    const isBrowser = typeof window !== 'undefined';
    const [selectedKit, setKit] = useState('none');
    const moveToDescription = () => {
        const nextDiv = document.getElementById('description-area');
        const yOffset = nextDiv.getBoundingClientRect().top;
        isBrowser && window.scrollBy({ top: yOffset - 60, behavior: 'smooth' });
    };
    useEffect(() => {
        if (selectedKit !== 'none') {
            moveToDescription();
        }
    }, [selectedKit]);
    const onClickFunction = (id) => {
        if (id === selectedKit) {
            moveToDescription();
        } else {
            setKit(() => id);
        }
    };
    return (
        <div>
            <div>
                <div css={introductionBoxStyle}>
                    <div css={h2AreaStyle}>
                        <h2 css={h2Style}>キット販売中！</h2>
                        <BaseLinkButton />
                    </div>
                    <p>
                        例年EEICでは五月祭で電子工作を体験できるキットを制作しています。
                    </p>
                </div>
                <div>
                    <h2>キットの説明はこちら↓</h2>
                    <div css={cardBoxStyle}>
                        {cardData.map((data, index) => (
                            <button
                                key={index}
                                type="button"
                                onClick={() => onClickFunction(data.id)}
                            >
                                <CraftKitCard {...data} />
                            </button>
                        ))}
                    </div>
                    <div id="description-area">
                        {/* <LogicCircuitDescription /> */}
                        {selectedKit === 'logic' && <LogicCircuitDescription />}
                        {selectedKit === 'transistor' && (
                            <TransistorDescription />
                        )}
                        {selectedKit === 'micro-computer' && (
                            <MicroComputerDescription />
                        )}
                    </div>
                    {selectedKit !== 'none' && (
                        <div css={cardBoxStyle}>
                            {cardData.map(({ introduction, id }, index) => (
                                <button
                                    key={index}
                                    onClick={() => onClickFunction(id)}
                                    type="button"
                                >
                                    <BottomButton
                                        key={index}
                                        text={introduction}
                                    />
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const cardData = [
    {
        introduction: <>論理回路キット</>,
        headImage: 'electronic-workshop/logic-circuit/header.jpeg',
        id: 'logic',
    },
    {
        introduction: <>トランジスタキット</>,
        headImage: 'electronic-workshop/transistor/header.jpeg',
        id: 'transistor',
    },
    {
        introduction: <>マイコンキット</>,
        headImage: 'electronic-workshop/micro-computer/header.jpeg',
        id: 'micro-computer',
    },
];

const introductionBoxStyle = css`
    margin-top: 35px;
`;

const cardBoxStyle = css`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 10px 20px 20px;
`;

const h2AreaStyle = css`
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: ${breakPoints.mobile}) {
        display: block;
    }
`;

const h2Style = css`
    margin-right: 10px;
`
