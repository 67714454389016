import { css } from '@emotion/react';
import React from 'react';
import { LinkButtonExternal } from '../LinkButton';
import { Image } from '../Image';

export const Goods = () => {
    return (
        <div css={container}>
            <div css={sentenceStyle}>
                EEICオリジナルVTuber「みつえれ」のオリジナルグッズを販売したします。
                <br />
                工学部2号館241号講義室および
                <span css={buttonStyle}>
                    <LinkButtonExternal
                        title="こちらのリンク"
                        url="https://mayfes2022.base.shop/items/62477414"
                    />
                </span>
                から購入いただけます。
                <br />
                【販売期間】
                <br />
                2022年5月14日(土)・15日(日)
            </div>
            <div css={imageStyle}>
                <Image imageName="Goods.png" alt="acrylic key ring" />
            </div>
        </div>
    );
};

const container = css`
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    justify-content: center;
    align-items: center;
`;

const sentenceStyle = css`
    display: inline-block;
    text-align: left;
`;

const buttonStyle = css`
    display: inline-block;
    margin: 8px;
`;

const imageStyle = css`
    width: 320px;
`;
