import React from 'react';
import { css } from '@emotion/react';
import { ContentSection } from './ContentSection';
import { LinkButtonExternal } from './LinkButton';

export const MayFesAwards = () => {
    return (
        <ContentSection title="人気企画投票">
            <div css={style}>
                <div css={sentenceStyle}>
                    近未来体験2022は、五月祭のNo.1企画を決定する「五月祭総選挙
                    -May Fes.
                    Awards-」に参加しています。ぜひ投票をお願いします！
                </div>
                <div css={buttonContainer}>
                    <LinkButtonExternal
                        title="投票はこちらから"
                        url="https://gogatsusai.jp/95/visitor/online/kikaku/476"
                    />
                    <LinkButtonExternal
                        title="五月祭総選挙とは"
                        url="https://gogatsusai.jp/95/visitor/awards"
                    />
                </div>
            </div>
        </ContentSection>
    );
};

const style = css`
    text-align: center;
`;

const sentenceStyle = css`
    display: inline-block;
    text-align: left;
    margin-bottom: 32px;
`;

const buttonContainer = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
`;
