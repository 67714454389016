import * as React from 'react';
import Layout from '../components/layout';
import { Seo } from '../components/Seo';
import { SectionEyecatch } from '../components/SectionEyecatch';
import { WebcastingDetail } from '../components/webcasting/WebcastingDetail';
import { RecommendSection } from '../components/RecommendSection';

const WebcastingPage = () => (
    <Layout>
        <Seo
            title="配信企画"
            pathname="/webcasting"
            keywords={[
                `EEIC`,
                `電子情報工学科`,
                `電気電子工学科`,
                `東京大学`,
                `工学部`,
                `電気系`,
                `VTuber`,
                `バーチャルYouTuber`,
                `御城みつき`,
                `風里えれな`,
                `mitsuele_eeic`,
                `eeic_studentadv`,
            ]}
            description="EEICオリジナルVTuber「みつえれ」が近未来体験2022をご紹介！"
        />
        <SectionEyecatch
            pageTitle="配信企画"
            pageSentences="EEICオリジナルVTuberみつえれが「近未来体験2022」をご紹介！"
        />
        <WebcastingDetail />
        <RecommendSection recommendData={recommendData} />
    </Layout>
);

const recommendData = [
    {
        title: 'プログラミング教室',
        introduction: 'えれなの一押し企画を見てみよう！',
        path: '/programming-workshop',
    },
    {
        title: '研究室展示',
        introduction: '研究室のサイトを見てみよう！',
        path: '/laboratory',
    },
];

export default WebcastingPage;
